@import "../_prestashop/partials/_commons.scss";
@import "./commons/buttons.scss";
@import "./commons/modal.scss";
@import "./commons/swiper.scss";
@import "./commons/form.scss";
@import "./commons/rodo.scss";
@import "./commons/pagination.scss";
@import "./commons/alert.scss";

html {
  font-size: $rem-value;
}
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.container-fluid.padded {
  padding-left: 20px;
  padding-right: 20px;
}
main {
  overflow: hidden;   // for menu ::before white bg
}
a {
  color: initial;
}
p {
  color: $black;
}

// TEXTS
/* %ul {
  li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &::before {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          background: $primary-color;
          margin-right: 1rem;
      }
  }
  ul {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
} */

// TU BYŁY REGUŁY: buttons.scss

[data-toggle="collapse"] {
  &[aria-expanded="true"] i {
    @extend .ib-flipY;
  }
}

// TU BYŁY REGUŁY: form.scss

.ib-flipX {
  display: inline-block;
  transform: scaleX(-1);
}
.ib-flipY {
  display: inline-block;
  transform: scaleY(-1);
}
// ANIMATIONS AND TRANSITIONS
.ib-transition-bg {
  transition: background-color $tspeed-250 ease-out;
}
.ib-transition-text {
  transition: color $tspeed-250 ease-out;
}
.ib-transition-all {
  transition: $tspeed-250 ease-out !important;
}
.ib-bg-whitesmoke {
  background-color: $whiteSmoke;
}
.ib-text-hover-primary:hover {
  color:$primary-color !important;
}
.ib-bg-hover-primary{
  background-color: $primary-color;
}
.ib-text-primary{
  color:$primary-color;
}
.ib-bg-primary{
  background-color: $primary-color;
}
.ib-lh-100 {
  line-height: 1;
}
.ib-lh-125 {
  line-height: 1.25;
}
.ib-lh-150 {
  line-height: 1.5;
}
.ib-lh-175 {
  line-height: 1.75;
}
.ib-position-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ib-overlay {
  background: rgba(0, 0, 0, 0.15) !important;
}
.ib-text-red {
  color: $red;
}
.ui-widget {
  font-family: inherit;
}
hr.ib-index {
  border-top: 1px solid $ib-index-hr-gray;
  border-bottom: none;
  margin-left: -5rem;
  margin-right: -5rem;
}
.ib-attachments {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px 30px;
  list-style: none !important;  // .list-inline doesn't have important rule
  li {
    a {
      padding-top: .45rem;
      padding-bottom: .45rem;
      border: 1px transparent solid;
      box-shadow: 0 0 19px 0px #e5dfdf;
      transition: 0.3s;
      border-radius: 10px;
      &:hover {
        border-color: $primary-color;
        box-shadow: none;
      }
    }
  }
  .ext {
    width: 26px;
    height: 26px;
    min-width: 26px;
    background: url('../img/download/down-arrow.svg') no-repeat center;
    background-size: contain;
    &.pdf {
      background-image: url('../img/download/pdf.svg');
    }
    &.zip {
      background-image: url('../img/download/zip.svg');
    }
    &.doc {
      background-image: url('../img/download/doc.svg');
    }
    &.xls {
      background-image: url('../img/download/xls.svg');
    }
    &.png {
      background-image: url('../img/download/png.svg');
    }
    &.jpeg, &.jpg {
      background-image: url('../img/download/jpeg.svg');
    }
  }
  .button {
    border: 1px $primary-color solid;
    border-radius: 25px;
    font-weight: 700;
    color:$black;
    transition: 0.3s;
    i{
      color:$primary-color;
    }
    &:hover{
      background-color: $primary-color;
      color:$white;
      i{
        color: $white;
      }
    }
    // i {
    //   width: 20px;
    //   height: 19px;
    //   background: url('../img/download/down-arrow.svg') no-repeat center;
    //   background-size: contain;
    // }
  }
}

// TU BYŁY REGUŁY: modal.scss
// TU BYŁY REGUŁY: swiper.scss
// TU BYŁY REGUŁY: pagination.scss
// TU BYŁY REGUŁY: alert.scss


/*********************/
/*        RWD        */
/*********************/

// @include media-breakpoint-down(sm) {
@include media-breakpoint-down(md) {
  .ib-attachments .button{
    border-radius: 5px;
  }
  .container-fluid.d-grid {
    display: flex !important;
  }
}
@include media-breakpoint-up(lg) {
  #index #wrapper .container-fluid.padded{
    padding-left: get-vw(20rem);
    padding-right: get-vw(20rem);
  }
  .container-fluid.padded {
    padding-left: get-vw(5rem);
    padding-right: get-vw(5rem);
  }
  .container-fluid.d-grid {
    grid-template-columns: get-vw(290) minmax(0, 1fr);
    column-gap: get-vw(1rem);
  }
  h2, .h2 {
    font-size: get-vw(2rem);
  }
  h6, .h6 {
    font-size: get-vw(1rem);
  }
  #product .ib-attachments{
    grid-template-columns: repeat(1, 1fr);
  }
  .ib-attachments {

    grid-template-columns: repeat(2, 1fr);
    li a{
      padding-top:0.75vw;
      padding-bottom: 0.75vw;
    }
    .button span{
      font-size: get-vw(13);
    }
    .ext {
      width: 33px;
      height: 33px;
      min-width: 33px;
    }
  }
}
