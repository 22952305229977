@import "ibificon_variables";

@font-face {
  font-family: '#{$ibificon-font-family}';
  src:
    // url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rfb47e') format('truetype'),
    // url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rfb47e') format('woff'),
    // url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rfb47e##{$icomoon-font-family}') format('svg');
    url('#{$ibificon-font-path}/#{$ibificon-font-family}.woff?rfb47e') format('woff'),
    url('#{$ibificon-font-path}/#{$ibificon-font-family}.woff?rfb47e') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin ibif-icon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$ibificon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

[class^="fibicon-"], [class*=" fibicon-"] {
  @include ibif-icon();
}

.fibicon-angle-small-up {
  &:before {
    content: $fibicon-angle-small-up; 
  }
}
.fibicon-arrow-down-sign-to-navigate {
  &:before {
    content: $fibicon-arrow-down-sign-to-navigate; 
  }
}
.fibicon-arrow-small-left {
  &:before {
    content: $fibicon-arrow-small-left; 
  }
}
.fibicon-arrow-small-right {
  &:before {
    content: $fibicon-arrow-small-right; 
  }
}
.fibicon-blind {
  &:before {
    content: $fibicon-blind; 
  }
}
.fibicon-briefcase {
  &:before {
    content: $fibicon-briefcase; 
  }
}
.fibicon-bubble-chat {
  &:before {
    content: $fibicon-bubble-chat; 
  }
}
.fibicon-calendar {
  &:before {
    content: $fibicon-calendar; 
  }
}
.fibicon-cancel {
  &:before {
    content: $fibicon-cancel; 
  }
}
.fibicon-check {
  &:before {
    content: $fibicon-check; 
  }
}
.fibicon-checked {
  &:before {
    content: $fibicon-checked; 
  }
}
.fibicon-cursor {
  &:before {
    content: $fibicon-cursor; 
  }
}
.fibicon-doc {
  &:before {
    content: $fibicon-doc; 
  }
}
.fibicon-down-arrow {
  &:before {
    content: $fibicon-down-arrow; 
  }
}
.fibicon-email_1 {
  &:before {
    content: $fibicon-email_1; 
  }
}
.fibicon-email_2 {
  &:before {
    content: $fibicon-email_2; 
  }
}
.fibicon-eye {
  &:before {
    content: $fibicon-eye; 
  }
}
.fibicon-facebook {
  &:before {
    content: $fibicon-facebook; 
  }
}
.fibicon-heart {
  &:before {
    content: $fibicon-heart; 
  }
}
.fibicon-heart-full {
  &:before {
    content: $fibicon-heart-full; 
  }
}
.fibicon-home {
  &:before {
    content: $fibicon-home; 
  }
}
.fibicon-info {
  &:before {
    content: $fibicon-info; 
  }
}
.fibicon-instagram {
  &:before {
    content: $fibicon-instagram; 
  }
}
.fibicon-jpeg {
  &:before {
    content: $fibicon-jpeg; 
  }
}
.fibicon-key {
  &:before {
    content: $fibicon-key; 
  }
}
.fibicon-left-arrow {
  &:before {
    content: $fibicon-left-arrow; 
  }
}
.fibicon-load {
  &:before {
    content: $fibicon-load; 
  }
}
.fibicon-lock {
  &:before {
    content: $fibicon-lock; 
  }
}
.fibicon-log-out {
  &:before {
    content: $fibicon-log-out; 
  }
}
.fibicon-loupe {
  &:before {
    content: $fibicon-loupe; 
  }
}
.fibicon-menu {
  &:before {
    content: $fibicon-menu; 
  }
}
.fibicon-minus {
  &:before {
    content: $fibicon-minus; 
  }
}
.fibicon-more {
  &:before {
    content: $fibicon-more; 
  }
}
.fibicon-notification {
  &:before {
    content: $fibicon-notification; 
  }
}
.fibicon-paint-brush {
  &:before {
    content: $fibicon-paint-brush; 
  }
}
.fibicon-pdf {
  &:before {
    content: $fibicon-pdf; 
  }
}
.fibicon-placeholder {
  &:before {
    content: $fibicon-placeholder; 
  }
}
.fibicon-plus {
  &:before {
    content: $fibicon-plus; 
  }
}
.fibicon-png {
  &:before {
    content: $fibicon-png; 
  }
}
.fibicon-previous {
  &:before {
    content: $fibicon-previous; 
  }
}
.fibicon-ribbon {
  &:before {
    content: $fibicon-ribbon; 
  }
}
.fibicon-right-arrow {
  &:before {
    content: $fibicon-right-arrow; 
  }
}
.fibicon-settings {
  &:before {
    content: $fibicon-settings; 
  }
}
.fibicon-shield {
  &:before {
    content: $fibicon-shield; 
  }
}
.fibicon-shopping-bag {
  &:before {
    content: $fibicon-shopping-bag; 
  }
}
.fibicon-shopping-cart {
  &:before {
    content: $fibicon-shopping-cart; 
  }
}
.fibicon-smartphone {
  &:before {
    content: $fibicon-smartphone; 
  }
}
.fibicon-star {
  &:before {
    content: $fibicon-star; 
  }
}
.fibicon-trash-can {
  &:before {
    content: $fibicon-trash-can; 
  }
}
.fibicon-truck {
  &:before {
    content: $fibicon-truck; 
  }
}
.fibicon-twitter {
  &:before {
    content: $fibicon-twitter; 
  }
}
.fibicon-undo {
  &:before {
    content: $fibicon-undo; 
  }
}
.fibicon-user {
  &:before {
    content: $fibicon-user; 
  }
}
.fibicon-user1 {
  &:before {
    content: $fibicon-user1; 
  }
}
.fibicon-user1a {
  &:before {
    content: $fibicon-user1a; 
  }
}
.fibicon-user1b {
  &:before {
    content: $fibicon-user1b; 
  }
}
.fibicon-user2 {
  &:before {
    content: $fibicon-user2; 
  }
}
.fibicon-user2a {
  &:before {
    content: $fibicon-user2a; 
  }
}
.fibicon-user2b {
  &:before {
    content: $fibicon-user2b; 
  }
}
.fibicon-user3a {
  &:before {
    content: $fibicon-user3a; 
  }
}
.fibicon-user3b {
  &:before {
    content: $fibicon-user3b; 
  }
}
.fibicon-wall-clock {
  &:before {
    content: $fibicon-wall-clock; 
  }
}
.fibicon-xls {
  &:before {
    content: $fibicon-xls; 
  }
}
.fibicon-youtube {
  &:before {
    content: $fibicon-youtube; 
  }
}
.fibicon-zip {
  &:before {
    content: $fibicon-zip; 
  }
}

