@import "../_prestashop/components/customer.scss";

#authentication,
#address,
#module-blockwishlist-lists,
#identity {
  #main {
    .page-header {
      margin-bottom: 0;
    }
    .page-footer {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #content {
    max-width: 1600px;
    // border: 1px $block-border-color solid;
    border-radius: 0;
    font-size: 1.125rem;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    &.login-register {
      max-width: 1280px;
      border: 0;
      display: grid;
      // grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      .block {
        @include block-with-icon-and-header;
        border-radius: 10px;
        &.login{
          border-color: $primary-color;
        }
        .btn-primary {
          font-weight: 500;
        }
      }
      .login-form {
        .login-form-fields {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2%;
          .form-control-label {
            &::after {
              display: none;
            }
          }
        }
      }
    }
    .renew-password,
    .register-form {
      max-width: 825px;
      .custom-checkbox {
        margin-left: -18px;
        cursor: pointer;
        a{
          // color: $primary-color;
          
          text-decoration: underline;
        }
      }
    }
  }
  .form-group {
    margin-bottom: 0;
  }  
}
#address {
  .address-form {
    max-width: 825px;
  }
}
.show-password {
  button {
    width: 50px;
    background: url("../img/eye.svg") center center no-repeat;
    background-size: 50% 50%;
  }
  top: 0;
  right: 0;
}
a:hover{
  color:$primary-color;
}
.page-customer-account {
  #content {
    .forgot-password {
      
      text-align: left;
      margin: 0;
      padding: 0;
      height: 50px;
      line-height: 50px;
      a {
        transition: 0.3s;
        display: flex;
        align-items: center;
        // color: $breadcrumb-color;
        font-size: 14px;
        .icon {
          position: relative;
        }
      }
    }
    .no-account {
      a {
        font-weight: 700;
        line-height: 50px;
      }
    }
  }
}
#back-to-login{
  font-weight: 700;
}
.page-my-account {
  #content {
    .links {
      max-width: 1280px;
      margin: auto;
      a {
        text-align: left;
        font-size: $base-font-size;
        font-weight: 700;
        color: $black;
        border: 1px solid transparent;
        box-shadow: 0 0 19px 0px #e5dfdf;
        margin-bottom: 1rem;
        padding: 0;
        border-radius: 25px;
        @extend .ib-transition-all;
        span{
          @extend .ib-transition-all;
          &.link-item {
            padding: 0.75rem 1rem;
            text-transform: none;
            position: relative;
          }
        }
        &::after {
          @extend .ib-transition-all;
          content: $fibicon-right-arrow;
          @include ibif-icon();
          position: absolute;
          right: 1rem;
          font-size: 16px;
          font-weight: 700;
          top: 50%;
          transform: translateY(-50%);
          color: $primary-color;
        }  
        .icon {
          color:$primary-color;
          padding:10px;
          // @include ibif-icon-span;
        }
        &:hover {
          box-shadow: none;
          border-color:$primary-color;
          // span {
          //   color: $primary-color;
          // }
          &::after {
            // content: $fibicon-more;
            
          }
        }
      }
    }
  }
}

.page-addresses {
  .address {
    // border: 1px $whiteSmoke solid;
    // border-radius: 0;
    .card {
      &-header,
      &-body,
      &-footer {
        border: 0;
        border-left: 1px $pagination-blog-border-color solid;
        border-right: 1px $pagination-blog-border-color solid;
        border-radius: 0;
      }
      &-header {
        border-top: 1px $pagination-blog-border-color solid;
        background: transparent;
        padding: 28px 40px;
      }
      &-body {
        padding: 0 40px;
      }
      &-footer {
        padding: 0 40px 28px;
        background: transparent;
        border-bottom: 1px $pagination-blog-border-color solid;
      }
    }
  }
}

#password {
  .renew-password {
    max-width: 468px;
  }
  &.page-password {
    #content {
      max-width: unset;
      margin-bottom: 0;
    }
  }
}

#order-detail,
#history {
  #content,
  .page-footer {
    padding: 0;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
  h6 {
    border-top: 1px $pagination-blog-border-color solid;
    border-bottom: 1px $pagination-blog-border-color solid;
  }
  table {
    thead {
      background: $whiteSmoke;
      th {
        border: 0;
        padding: 1rem;
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: $whiteSmoke;
        }
        &:first-child {
          td, th {
            border-top: 0;
          }
        }
        td {
          border-color: $block-border-color;
          padding: 1rem;
        }
        &:last-child {
          td, th {
            border-bottom: 1px solid $block-border-color;
          }
        }
      }
    }
  }
  .order-actions {
    a {
      width: 185px;
    }
  }
}

#order-history table thead th:first-child {
  width: 200px;
}

#order-products {
  thead th:first-child {
    width: 60%;
  }
  tfoot {
    border-bottom: 1px $block-border-color solid;
    td {
      border: 0;
    }
  }
}

.page-order-detail {
  #content {
    font-size: 16px;
  }
  .addresses {
    .box {
      border: 1px $block-border-color solid;
      padding: 1.25rem 1.5rem;
    }
  }
}

.order-message-form {
  border-bottom: 1px $block-border-color solid;
}
#authentication #content.login-register .block{
  input::placeholder {
    color:transparent;
}
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
  .popup_error{
    font-size: get-vw(16);
  }
  .btn-secondary.trustmate{
    width:12vw;
  }
  .required-text{
    font-size: get-vw(13);
  }
  #authentication #content, #address #content, #module-blockwishlist-lists #content, #identity #content{
    max-width: get-vw(940);
  }
  .login-form{
    margin-top: 1.35vw;
  }
  #authentication #content.page-content.login-register .block{
    padding:get-vw(45) get-vw(38);
    &.register{
      padding:get-vw(45) get-vw(45) get-vw(45) get-vw(38);
    }
  }
  .page-customer-account {
    #content {
      .forgot-password {
        height: get-vw(50);
        line-height: get-vw(50);
        a {
          // color: $breadcrumb-color;
          font-size: get-vw(14);
          
          .icon {
            position: relative;
            margin-right: get-vw(10);
          }
        }
      }
      .no-account {
        padding-top: get-vw(24);
        // position: absolute;
        // bottom: get-vw(40);
        // right: get-vw(40);
        a {
          line-height: get-vw(50);
        }
      }
    }
  }
  .page-customer-account #content .no-account{
    display: grid;
    grid-template-columns: repeat(2, 48.5%);
    gap: 3%;
  }
  #authentication {
    #content {
      &.login-register {
        .login-form {
          .login-form-fields {
            display: grid;
            grid-template-columns: repeat(2, 48.5%);
            gap: 3%;
          }
        }
      }
      .register-form {
        max-width: none;
        width: auto;
        .custom-checkbox {
          // margin-left: get-vw(-18);
          display: flex;
          align-items: center;

        }
      }  
    }
  }
  #address {
    .address-form {
      max-width: get-vw(825);
    }
  }
  .page-my-account {
    #content {
      &.page-content {
      margin-bottom: 5vw;
      }
      .links {
        a {
          font-size: get-vw(18);
          padding: 0 0.9375rem;
          span {
            &.link-item {
              padding: 0;
            }
          }
          &::after {
            font-size: 28px;
          }  
        }
      }
    }
  }
  .show-password {
    button {
      width: get-vw(65);
    }
  }
  .page-order-detail {
    #content {
      font-size: get-vw(16);
    }
  }
  .order-message-form {
    form {
      max-width: get-vw(800);
    }
  }  
}

@include media-breakpoint-up(sm) {
  .page-my-account #content .links{
    max-width: get-vw(910);
    .icon{
      margin-left: get-vw(18);
      margin-right: get-vw(15);
      padding:get-vw(5);
      font-size: get-vw(35);
    }
    a{
      margin-bottom: get-vw(16);
      padding:0;
      &::after{
        font-size: get-vw(28);
        right:get-vw(20);
      }
    }
  }
  #authentication {
    #content {
      font-size: get-vw(18);
      &.login-register {
        margin-bottom: get-vw(150);
        max-width: get-vw(1265);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: get-vw(40);
        .block {
          
          @include block-with-icon-and-header;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .page-my-account #content .links a .icon{
    font-size: 30px;
  }
  .page-order-detail {
    .text-xs-right {
      text-align: right !important;
    }
    .order-items {
      .order-item {
        .col-xs-4 {
          width: 33.33%;
        }
      }
    }
  }
  .page-history {
    .text-xs-right {
      text-align: right !important;
    }
  }
}