@import "../_prestashop/components/sitemap.scss";

.ib-bullet {
    width: 5px;
    height: 5px;
}
.sitemap {
    a {
        --color: #{$brown};
    }
    h2 {
        border-bottom: 1px solid $block-border-color;
    }
    // ul {
    //     @extend %ul;
    // }
}