@import "../_prestashop/components/cart.scss";

.cart-items {
    text-align: center;
}
.cart-item.cart-header {
    display: none;
    background-color: $whiteSmoke;
    font-weight: 700;
}

// .product-line-grid {
#cart .product-line-grid {
    .form-control,.bootstrap-touchspin .btn{
        border-radius: 7px!important;
    }
  
    // .bootstrap-touchspin .btn{
    //     width:get-vw(40);
    // }
    > span, > div {
        padding: 1rem 0.5rem;
    }
    .label {
        font-size: inherit;
    }
    .circle {
        width: 16px;
        height: 16px;
        &-green {
            background-color: $circle-green;
        }
        &-red {
            background-color: $circle-red;
        }
        &-orange {
            background-color: $circle-orange;
        }
    }
    .fibicon-trash-can {
        font-size: 28px;
        color:$primary-color;
    }
    .product-image {
        max-width: 160px;
    }
}
// .product-line-info.product-price {
//     color: inherit;
// }
.cart-item .availability > * {
    display: inline-block;
    vertical-align: middle;
}
// .cart-summary-line {
#cart .cart-summary-line:not(.list-group-item) {
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: center;
    border-bottom: 1px solid $block-border-color;
    .label {
        font-size: inherit;
    }
    // > span {
    //     padding: 1.25rem 0.5rem;
    // }
}
// .ib-group-qty-btn {
    // width: 130px;
    // .form-control {
    //     height: 34px;
    //     margin: 0 5px;
    //     width: 100%;
    //     text-align: center;
    // }
    // .bootstrap-touchspin {
    //     padding: 0 (34px + 5px);
    //     position: static;
    // }
    // .bootstrap-touchspin .input-group-btn-vertical {
    //     position: static;
    // }
    // .bootstrap-touchspin .input-group-btn-vertical > .btn {
    //     position: absolute;
    //     top: 0;
    //     margin: 0;
    //     padding: 0;
    //     height: 100%;
    //     width: 34px;
    //     &:hover {
    //         background-color: $black;
    //         color: $white;
    //     }
    // }
    // .bootstrap-touchspin-up {
    //     right: 0;
    // }
    // .bootstrap-touchspin-down {
    //     left: 0;
    // }
    // i {
    //     @include ibif-icon();
    //     position: static !important;
    //     &::after {
    //         content: none !important;
    //     }
    // }
    // i.touchspin-up {
    //     @extend .fibicon-plus;
    // }
    // i.touchspin-down {
    //     @extend .fibicon-minus;
    // }
// }
#cart .ib-group-qty-btn {
    width: 135px;
}
.block-promo {
    // margin-bottom: 1rem;
    form {
        display: grid;
        grid-template-columns: auto 100px;
        grid-gap: 15px;
    }
    .promo-name {
        .cart-summary-line {
            // align-items: center;
            .label {
                font-weight: 500;
                color: $gray-dark;
            }
            span {
                color: $primary-color;
            }
        }
    }
    &.promo-highlighted,
    .list-group-item {
        border: 1px $block-border-color solid;
        .code {
            font-weight: 800;
        }
    }
}
// .ib-voucher-label {
//     font-weight: 600;
// }
.ib-cart-bottom .btn {
    font-weight: 600;
    &.disabled:first-child {
        float: right;
    }
}
#cart .no-items {
    background: $ib-index-hr-gray;
}
.product-total-price{
    font-weight: 600;
}
.product-line-info.product-name{
    font-weight: 400;
}
.ib-cart-bottom {
    .btn-secondary{
        font-weight: 700;
    }
}
/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    #cart .cart-item {
        border: 1px solid $block-border-color;
        margin-bottom: 1rem;
        position: relative;
        padding: 0.5rem;
        .product-line-grid {
            display: flex;
            flex-wrap: wrap;
            > div {
                width: 100%;
                padding: 0.5rem;
            }
        }
        div.cart-line-product-actions {
            position: absolute;
            right: 0;
            top: 0;
            padding: 1rem;
            width: auto;
        }
    }
    .cart-item {
        .availability,
        .product-line-grid-total-price {
            order: 1;
        }
    }
    .product-total-price {
        font-weight: 600;
        font-size: 18px;
    }
    .ib-cart-bottom {
        order: -1;
    }
    #promo-code .btn {
        height: 40px;
        font-size: 12px;
    }
    .ib-cart-total-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
    }
    #cart .cart-summary {
        padding-bottom: 70px;
    }
    // .checkout.cart-detailed-actions {
    .ib-cart-total-actions .checkout {
        margin: 0 -15px;
        .btn {
            font-size: 14px;
        }
    }
    #cart .cart-summary-line {
        font-size: 1rem;
        .label {
            text-align: left;
        }
        .value {
            font-weight: 600;
            font-size: 20px;
            text-align: center;
        }
    }
    .ib-cart-totals {
        border-top: 1px solid $block-border-color;
        margin-top: 2rem;
    }
}
@include media-breakpoint-down(xs) {
    #cart .cart-summary {
        padding-bottom: 125px;
    }
    // .checkout.cart-detailed-actions > div {
    .ib-cart-total-actions .checkout > div {
        margin-top: 10px;
    }
}
.products-in-cart{
    font-weight: 700;
}
@include media-breakpoint-up(lg) {
    #cart header#header, #checkout header#header,#pagenotfound header#header {
        margin-bottom: 1.6vw !important;
        box-shadow: 0px 12px 3vw 1vw #ececec;
    }
    // .cart-detailed-actions {
    .ib-cart-total-actions .checkout {
        padding-top: 20px;
    }
    .cart-item.cart-header {
        display: block;
    }
    .block-promo {
        margin-bottom: get-vw(1rem);
        form {
            grid-template-columns: auto get-vw(155px);
            gap:0.5vw;
            margin-right: 0.5vw;
        }
    }
    #cart .cart-grid {
        margin-bottom: get-vw(1rem);
    }
    #cart .product-line-grid {
        display: grid;
        grid-template-columns: get-vw(150) 1fr get-vw(224) get-vw(222) get-vw(225) get-vw(278) get-vw(78);
        > span {
            padding: get-vw(0.9rem) get-vw(0.5rem);
        }
         > div{
            padding: get-vw(1rem) get-vw(0.5rem);
         }
        > span {
            border-right: 1px $white solid;
        }
        .circle {
            width: get-vw(16);
            height: get-vw(16);
        }
        .fibicon-trash-can {
            font-size: get-vw(28);
        }
        .product-image {
            max-width: get-vw(75);
        }
        .bootstrap-touchspin .form-control{
            height: get-vw(36);
            margin:0 get-vw(7);
        }
        .bootstrap-touchspin .btn{
            width: get-vw(36);
            font-size: get-vw(16);
        }
       
    }
    #cart .cart-summary-line.cart-total .value{
        font-size: get-vw(24);
        font-weight: 600;
    }
    #cart .cart-summary-line:not(.list-group-item) {
        grid-template-columns: 1fr get-vw(275) get-vw(75);
        > span {
            padding: get-vw(1.25rem) get-vw(0.5rem);
        }
    }
    .ib-cart-total-actions {
        padding-right: get-vw(75);
    }
    // .cart-item + .cart-item {
    #cart .cart-item + .cart-item {
        border-bottom: 1px solid $block-border-color;
    }
    #cart .ib-group-qty-btn {
        width: get-vw(135);
    }
    .ib-cart-bottom .btn {
        font-size: get-vw(14);
        width:get-vw(165);
    }
    .cart-discount-img{
        padding-right: get-vw(130);
    }
}