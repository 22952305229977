// @import "../_prestashop/components/block-reassurance.scss";

#block-reassurance {
  i {
    font-size: 24px;
    color: $brown;
  }
  li.block-reassurance-item {
    gap: 20px;
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .block-reassurance-item + .block-reassurance-item {
    border-top: 1px solid $block-border-color;
  }
}
@include media-breakpoint-up(lg) {
  #block-reassurance {
    i {
      font-size: get-vw(40);
    }
    li.block-reassurance-item {
      gap: get-vw(40);
    }
  }
}