nav.pagination {
    .current>.btn-secondary {
        background-color: $primary-color;
        color: $white;
        pointer-events: none;
        // &::before, &::after {
        //   content: none;
        // }
    }
    .btn-secondary {
        padding: 0;
        font-size: 0.875rem;
        // font-weight: normal;
    }
    .number {
        border:none;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        transition: 0.3s;
    }
    // only directional links have class btn 
    .btn {
        &.disabled {
            opacity: .4;
        }
    }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    .container-fluid.d-grid {
        display: flex !important;
    }
    #category nav.pagination {
        .previous,
        .next {
          width: 30px;
          height: 30px;
        }
        .number {
          width: 30px;
          line-height: 30px;
        }
      }
    
}
@include media-breakpoint-up(lg) {
    nav.pagination {
        .btn-secondary {
            font-size: get-vw(0.875rem);
            width:get-vw(230);
        }
        .number {
            width: get-vw(50px);
            line-height: get-vw(50px);
        }
    }
    .ib-attachments {
        grid-template-columns: repeat(2, 1fr);
        .ext {
            width: 33px;
            height: 33px;
            min-width: 33px;
        }
    }
}