@import "../_prestashop/components/search-widget.scss";

.header-top {
  #search_widget {
    margin-left: 4%;
    > div {
      z-index: 1001;
    }
  }
}

#search_widget {
  width: 46%;
  // margin-left: auto;
  // margin-right: auto;
  // margin-left: 6%;
  // margin-left: 4%;
  + div {
    margin-left: auto;
  }
  > div {
    z-index: 1001;
  }
  @extend .ib-transition-all;
}
// #search_widget form {
  // z-index: 1000;
// }
// #search_widget .ui-autocomplete-input {
#search_widget form input {
  // @extend .form-control;
  padding: 0.5rem 1rem;
  color: $input-text-color;
  background: $input-bg-color;
  border: $input-btn-border-width solid $input-border-color;
  height: 46px;
  border-radius: 0;
  &:focus {
    color: $gray-darker;
    background-color: $white;
    // outline: 0.1875rem solid $brand-primary;
  }
}
#search_widget form i.clear {
  display: none !important;
}
// #search_widget .btn {
//   @extend .ib-transition-all;
// }
#search_widget .searchbar-autocomplete {
  font-family: 'Poppins', sans-serif;
  position: absolute;
  min-width: 100%;
  max-width: 100%;
  left: 0 !important;
  top: 0 !important;
  padding: 10px 0;
  z-index: -1;
  &::before {
    content: '';
    position: absolute;
    top: -70px;
    // left: -35px;
    // right: -35px;
    left: -22px;
    right: -22px;
    bottom: -20px;
    background: $white;
    z-index: -1;
    // box-shadow: 0 0 5px;
    @extend .shadow;
  }
}
#search_widget .autocomplete-thumbnail {
  width: 75px;
}
#search_widget .ui-menu-item {
  border-bottom: 1px solid $ib-index-hr-gray;
  font-size: 1rem;
}
#search_widget .ui-menu-item a {
  padding: 0;
  gap: 1rem;
  &:hover {
    color: $brown;
  }
}
#search_widget .flex-column {
  gap: 0.75rem;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .header-top {
    #search_widget {
      position: absolute;
      left: 20px;
      // bottom: 0;
      top: 100%;
      right: 20px;
      width: auto;
      margin-left: 0;
      margin-bottom: 0.625rem;
      z-index: 0;
      opacity: 0;
      visibility: hidden;
      background-color: $white;
      padding-bottom: 0.625rem;
      // > div {
      //   margin-right: 62px;
      // }
    }
    #search_widget .searchbar-autocomplete {
      max-width: none;
      // right: -60px;
      width: auto !important;
    }
    #search_widget_trigger {
      i {
        font-size: 22px;
      }
      &.active + #search_widget {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }
  #search_widget .btn {
    background: none;
    color: $black;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: auto;
    // font-size: 24px;
    // padding-left: 0.5rem !important;
    // padding-right: 0.5rem !important;
    i {
      font-size: 22px;
      font-weight: 600;
    }
    &::before {
      border: 0;
    }
  }
}
@include media-breakpoint-up(lg) {
  .header-top {
    #search_widget form input {
      height: get-vw(46);
    }
    #search_widget .ui-menu-item {
      font-size: get-vw(1rem);
    }
    #search_widget .autocomplete-thumbnail {
      width: get-vw(75);
    }
    #search_widget .searchbar-autocomplete {
      &::before {
        top: get-vw(-70);
        left: get-vw(-35);
        right: get-vw(-35);
        bottom: get-vw(-20);
      }
    }
  }
}