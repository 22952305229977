.ib-swiper-button {
    &-next,
    &-prev {
        @extend .ib-transition-all;
        color: $black;
        cursor: pointer;
        border: 1px solid $pagination-blog-border-color;
        width: 34px;
        line-height: 34px;
        margin-top: -17px;
        top: 50%;
        z-index: 2;
        font-size: 20px;
        font-weight:700 ;
        &:hover {
            border-color: $primary-color;
            color: $primary-color;
        }
    }
    &-next {
        right: 0;
    }
    &-prev {
        left: 0;
    }
}
.swiper-pagination {
    line-height: 1;
    .swiper-pagination-bullet {
        --swiper-pagination-bullet-width: 10px;
        --swiper-pagination-bullet-height: 10px;
        --swiper-pagination-bullet-inactive-opacity: 1;
        background-color: $pagination-blog-border-color;
    }
    .swiper-pagination-bullet-active {
        // background-color: $brown;
        background-color: $black;
    }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
    .ib-swiper-button {
        &-next, 
        &-prev {
            width: get-vw(34);
            line-height: get-vw(34);
            margin-top: get-vw(-17);
            font-size: get-vw(28);
        }
    }
}