@import "../_prestashop/components/custom-text.scss";

#custom-text {
  a {
    @extend .ibe_block-sparkle;
    display: block;
    --color: $primary-color;
    --padding: 8px;
  }
}

#cms {
  #content-wrapper {
    &:not(.left-column) {
      max-width: 1385px;
      margin: auto;
    }
  }
  .ib_cms_wrapper {
    a:not([class^=carousel-]) {
      --color: #{$primary-color};
      @extend .ib-transition-all;
      @extend .ibe_text-underline;    
    }
  }
  .page-content.page-cms {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      font-weight: 600;
      margin-bottom: 1rem;
    }
    h2, h4, h5, h6 {
      color: $primary-color;
    }
    ol {
      @extend p;
      padding-left: 0;
      list-style-position: inside;
    }
    ol,
    ul {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    blockquote {
      @include ibif-blockquote;
    }
    a {
      color: $primary-color;
      // @extend .ib-transition-all;
      // @extend .ibe_text-underline;
    }
    // ul.download {
    //   @include ibif-attachments;
    // }
    table {
      width: 100%;
      margin-bottom: 2rem;
      tbody {
        tr {
          &:first-child {
            td {
              font-weight: 700;
              background: $whiteSmoke;
              border-bottom: 0;
              // &:nth-child(2n) {
              //   background: $primary-color;
              //   color: $white;
              // }
            }
          }
          td {
            padding: 10px 40px;
            line-height: 1.25rem;
            border-bottom: 1px $block-border-color solid;
            text-align: center;
            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
    .cms-gallery {
      .cms-gallery-item {
        cursor: pointer;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: auto;
          opacity: 0;
          background-color: rgba(0,0,0,0.5);
          margin-left: 15px;
          margin-right: 15px;
          .overlay-text {
            color: white;
            font-size: 0.8rem;
            position: absolute;
            bottom: 1rem;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            text-align: center;
          }
          &::before {
            content: $fibicon-loupe;
            @include ibif-icon();
            position: absolute;
            left: 50%;
            font-size: 28px;
            font-weight: 500;
            color: $white;
            background: $primary-color;
            border-radius: 50%;
            padding: 0.75rem;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
          }
        }
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      }
    }
  }  
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(xs) {
  #cms .page-content.page-cms table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .page-content.page-cms {
    h1, .h1 { font-size: 25px; }
    h2, .h2 { font-size: 25px; }
    h3, .h3 { font-size: 20px; }
    h4, .h4 { font-size: 18px; }
    h5, .h5 { font-size: 16px; }
    h6, .h6 { font-size: 14px; }
  }
}
@include media-breakpoint-up(lg) {
  #cms{
    font-size: get-vw(18);
  }
  #cms #content-wrapper:not(.left-column)
  {
    max-width: 74vw;
  }
  .product-attachments .download a{
    font-size: get-vw(16);
  }
  #cms .page-content.page-cms{
    .download a{
      font-size: get-vw(16);
    }
    h1, .h1{
      font-size: get-vw(32);
    }
    h2, .h2{
      font-size: get-vw(26);
    }
    h3, .h3{
      font-size: get-vw(20);
    }
    h4, .h4{
      font-size: get-vw(16);
    }
  }
  #cms .page-content.page-cms table {
    tbody {
      td{
        border-right: 1px solid #fff;
      }
      tr {
        &:first-child {
          td {
            &:nth-child(2n) {
              // background: $primary-color;
              // color: $white;
            }
          }
        }
      }
    }
  }
}