@font-face {
  font-family: 'pigamicon';
  src:  url('pigamicon.eot?leiz13');
  src:  url('pigamicon.eot?leiz13#iefix') format('embedded-opentype'),
    url('pigamicon.ttf?leiz13') format('truetype'),
    url('pigamicon.woff?leiz13') format('woff'),
    url('pigamicon.svg?leiz13#pigamicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="pigamicon-"], [class*=" pigamicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pigamicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pigamicon-angle-small-up:before {
  content: "\e900";
}
.pigamicon-arrow-down-sign-to-navigate:before {
  content: "\e901";
}
.pigamicon-arrow-small-right:before {
  content: "\e902";
}
.pigamicon-briefcase:before {
  content: "\e903";
}
.pigamicon-bubble-chat:before {
  content: "\e904";
}
.pigamicon-calendar:before {
  content: "\e905";
}
.pigamicon-cancel:before {
  content: "\e906";
}
.pigamicon-check:before {
  content: "\e907";
}
.pigamicon-checked:before {
  content: "\e908";
}
.pigamicon-cursor:before {
  content: "\e909";
}
.pigamicon-doc:before {
  content: "\e90a";
}
.pigamicon-down-arrow:before {
  content: "\e90b";
}
.pigamicon-email_1:before {
  content: "\e90c";
}
.pigamicon-email_2:before {
  content: "\e90d";
}
.pigamicon-facebook:before {
  content: "\e90e";
}
.pigamicon-fr .path1:before {
  content: "\e90f";
  color: rgb(240, 240, 240);
}
.pigamicon-fr .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-fr .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-ge .path1:before {
  content: "\e912";
  color: rgb(255, 218, 68);
}
.pigamicon-ge .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.pigamicon-ge .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-gift-box-benefits:before {
  content: "\e915";
}
.pigamicon-heart:before {
  content: "\e916";
}
.pigamicon-home:before {
  content: "\e917";
}
.pigamicon-instagram:before {
  content: "\e918";
}
.pigamicon-it .path1:before {
  content: "\e919";
  color: rgb(240, 240, 240);
}
.pigamicon-it .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-it .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(109, 165, 68);
}
.pigamicon-jpeg:before {
  content: "\e91c";
}
.pigamicon-key:before {
  content: "\e91d";
}
.pigamicon-left-arrow .path1:before {
  content: "\e91e";
  color: rgb(244, 67, 54);
}
.pigamicon-left-arrow .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.pigamicon-load:before {
  content: "\e920";
}
.pigamicon-loading:before {
  content: "\e921";
}
.pigamicon-lock:before {
  content: "\e922";
}
.pigamicon-log-out:before {
  content: "\e923";
}
.pigamicon-loupe:before {
  content: "\e924";
}
.pigamicon-menu:before {
  content: "\e925";
}
.pigamicon-minus:before {
  content: "\e926";
}
.pigamicon-mobile-notch:before {
  content: "\e927";
}
.pigamicon-more:before {
  content: "\e928";
}
.pigamicon-notification:before {
  content: "\e929";
}
.pigamicon-paint-brush:before {
  content: "\e92a";
}
.pigamicon-pdf:before {
  content: "\e92b";
}
.pigamicon-placeholder:before {
  content: "\e92c";
}
.pigamicon-plus:before {
  content: "\e92d";
}
.pigamicon-png:before {
  content: "\e92e";
}
.pigamicon-pl .path1:before {
  content: "\e92f";
  color: rgb(240, 240, 240);
}
.pigamicon-pl .path2:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-previous:before {
  content: "\e931";
}
.pigamicon-ribbon:before {
  content: "\e932";
}
.pigamicon-right-arrow .path1:before {
  content: "\e933";
  color: rgb(244, 67, 54);
}
.pigamicon-right-arrow .path2:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.pigamicon-ru .path1:before {
  content: "\e935";
  color: rgb(240, 240, 240);
}
.pigamicon-ru .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-ru .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-search:before {
  content: "\e938";
}
.pigamicon-settings:before {
  content: "\e939";
}
.pigamicon-shield:before {
  content: "\e93a";
}
.pigamicon-shopping-bag:before {
  content: "\e93b";
}
.pigamicon-shopping-cart:before {
  content: "\e93c";
}
.pigamicon-smartphone:before {
  content: "\e93d";
}
.pigamicon-sp .path1:before {
  content: "\e93e";
  color: rgb(255, 218, 68);
}
.pigamicon-sp .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-sp .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-star:before {
  content: "\e941";
}
.pigamicon-tiktok:before {
  content: "\e942";
}
.pigamicon-trash-can:before {
  content: "\e943";
}
.pigamicon-truck:before {
  content: "\e944";
}
.pigamicon-twitter:before {
  content: "\e945";
}
.pigamicon-ua .path1:before {
  content: "\e946";
  color: rgb(255, 218, 68);
}
.pigamicon-ua .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(51, 138, 243);
}
.pigamicon-undo:before {
  content: "\e948";
}
.pigamicon-uk .path1:before {
  content: "\e949";
  color: rgb(240, 240, 240);
}
.pigamicon-uk .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path7:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path8:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path9:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.pigamicon-uk .path10:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-uk .path11:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-uk .path12:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-uk .path13:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-uk .path14:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.pigamicon-user:before {
  content: "\e957";
}
.pigamicon-wall-clock:before {
  content: "\e958";
}
.pigamicon-xls:before {
  content: "\e959";
}
.pigamicon-youtube:before {
  content: "\e95a";
}
.pigamicon-zip:before {
  content: "\e95b";
}
