.btn {
    // width: get-vw(230);
    // height: get-vw(50);
    width: 230px;
    height: 50px;
    max-width: 100%;
    border-radius: 0;
    position: relative;
    font-weight: 600;
    &-lg,
    .btn-group-lg>& {
        width: get-vw(285);
    }
    &-sm,
    .btn-group-sm>& {
        width: auto;
    }
    &-xs,
    .btn-group-xs>& {
        width: auto;
        height: get-vw(30);
        font-weight: 300 !important;
        padding: 0.25rem 1.5rem !important;
        font-size: 0.875rem;
    }
    &:focus {
        box-shadow: none;
    }
    @include btn-active {
        border-color: $primary-color;
    }
    &:active {
        box-shadow: none;
        border-color: $primary-color;

        &:focus {
            box-shadow: none;
        }
    }
    @include btn-disabled {
        &::before,
        &::after {
            content: none;
        }
    }
}
a.btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-primary,
.btn-secondary,
.btn-tertiary {
    border-radius: 25px;
    padding: 0.5rem 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
}
.btn-primary {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $white;
    @include btn-hover {
        color: $black;
        background-color: $white;
        border-color: $primary-color;
    }
    @include btn-active {
        border-color: $primary-color;
        color: $black;
        background-color: $white;
    }
    @include btn-disabled {
        background: $ib-index-hr-gray;
        color: $breadcrumb-color;
        border-color: $primary-color;
        cursor: not-allowed;
    }
    // @extend .ibe_block-border;
    // --width: 1px;
}
.btn-outline-primary {
    border-color: $black;
    background-color: $black;
    color: $white;
    @include btn-hover {
        border-color: $black;
        color: $black;
        background-color: $white;
    }
    @include btn-active {
        border-color: $primary-color;
        color: $black;
        background-color: $white;
    }
    @include btn-disabled {
        background: $ib-index-hr-gray;
        border-color: $input-border-color;
        color: $breadcrumb-color;
        cursor: not-allowed;
        &::before,
        &::after {
            content: none;
        }
    }
}
.btn-secondary {
    background-color: $white;
    color: $black;
    border: 1px solid $primary-color;
    // border-radius: 15px;
    // box-shadow: inset 0 0 0 1px $pagination-blog-border-color  !important;
    @include btn-hover {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
    }
    @include btn-active {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
        &::before,
        &::after {
            --color: #{$primary-color};
        }
    }
    // @extend .ibe_block-sparkle;
    // &::before,
    // &::after {
    //     // --color: #{$primary-color};
    //     --width: 1px;
    //     --speed: #{$tspeed-300};
    // }
    @include btn-disabled {
        background: $ib-index-hr-gray;
        color: $breadcrumb-color;
        cursor: not-allowed;
        &::before,
        &::after {
            content: none;
        }
    }
}
.btn-tertiary {
    border-radius: 25px;
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
    @include btn-hover {
        color: $black;
        background-color: $white;
        border-color: $primary-color;
    }
}

#back-to-login {
    margin: auto;
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    .btn {
        height: 45px;
        width: 100%;
    }
}
@include media-breakpoint-up(lg) {
    .btn {
        width: get-vw(260);
        height: get-vw(50);
        font-size: get-vw(14);
    }
    .btn-md {
        width: get-vw(160);
        height: get-vw(48);
    }
    .btn-sm {
        font-size: get-vw(0.875rem);
        width: auto;
    }
    .btn-xs {
        font-size: get-vw(0.875rem);
        width: auto;
        height: get-vw(30);
    }
    .btn-primary,
    .btn-secondary,
    .btn-tertiary {
        padding: get-vw(0.5rem) get-vw(1.25rem);
        // font-weight: 600;
    }
}