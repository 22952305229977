.search-widget {
  display: inline-block;
  @include search-box();
}

.header-top {
  &-right {
    @include media-breakpoint-down(xs) {
      width: 100%;
      padding-right: 15px;
    }
  }

  .search-widgets {
    form {
      input[type="text"] {
        width: 100%;
        min-width: inherit;
        color: $gray-second;
        // background: $gray-lighter;

        &:focus {
          background: $white;
        }
      }

      // button[type="submit"] {
      //   top: 50%;
      //   bottom: inherit;
      //   transform: translateY(-50%);
      // }
    }
  }

  // stylelint-disable-next-line
  #search_widget {
    input {
      padding-right: 1rem;
    }
  }
}

// #checkout {
//   #search_widget {
//     display: none; /* Not ideal solution by allows to reuse same hooks/templates */
//   }
// }

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
  .error-404-img {
    background: url('../img/404.svg') top center no-repeat;
    background-size: 72%;
    padding-top: 200px;
  }
  .error-404-content {
    .btn-secondary{
      font-weight: 700;
    }
    h2 {
      font-size: 24px;
      font-weight: 900;
    }
    p {
      
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }
    #search_widget {
      float: none;
    }
  }
}

.page-not-found {
  .search-widget {
    form {
      display: inline-block;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      float: none;

      form {
        margin: 0 auto;

        input[type="text"] {
          min-width: inherit;
          background: $white;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search-widget {
    min-width: 15.63rem;
  }
}

@include media-breakpoint-up(lg) {
  #pagenotfound {
    .error-404-img {
      padding-top: get-vw(600);
      margin-left: 3.8vw;
      background-position: 100% 3.8vw;
    }
    .error-404-content {
      padding-top: get-vw(175);
      padding-left: get-vw(75);
      h2 {
        font-size: get-vw(60);
      }
      p {
        font-size: get-vw(35);
        line-height: get-vw(45);
      }
    }  
  }
}
/* @include media-breakpoint-up(sm) {
  .header-top {
    .search-widgets {
      max-width: 13rem;
    }
  }
} */
