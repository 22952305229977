/*** IBIF ***/

section.ibif-logobrands {
    .ib-cover {
        z-index: 2;
    }
    .ibe_block-grow {
        --scale: 1.2;
        // --speed: #{$tspeed-500};
    }
    img {
        // filter: grayscale(1);
        max-width: 100px !important;
    }
    // a:hover img {
    //     filter: none;
    // }
}
.ib-logobrands-subpage {
    position: relative;
    top: 1px;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(xs) {
    section.ibif-logobrands {
        .swiper-slide {
            max-width: 210px;
        }
        .swiper-wrapper {
            top: -20px;
        }
        h2 {
            text-align: center;
        }
    }
}