@import "~bootstrap/scss/bootstrap";

// bootstrap 5.x classes that are missing in bootstrap 4.6 (other rules are in utility_rules - generated automatically)

.overflow-visible {
    overflow: visible !important;
}
.text-decoration-line-through {
    text-decoration: line-through;
}
.fw-bolder {
    font-weight: bolder!important;
}
.fw-lighter {
    font-weight: lighter!important;
}
.d-grid {
    display: grid!important;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
    // GRID
        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
        .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
        .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
        .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
        .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
        .col-xl-auto {
            padding-right: get-vw(15);
            padding-left: get-vw(15);
        }
        .row {
            margin-right: get-vw(-15);
            margin-left: get-vw(-15);
        }

    // FONTS
        h3, .h3 {
            font-size: get-vw(1.75rem);
        }
        h4, .h4 {
            font-size: get-vw(1.5rem);
        }
        h5, .h5 {
            font-size: get-vw(1.25rem);
        }

    // GRID SPACING
        .m-5 {
            margin: get-vw(3rem) !important;
        }
        .mt-1, .my-1 {
            margin-top: get-vw(0.25rem) !important;
        }
        .mt-3, .my-3 {
            margin-top: get-vw(1rem) !important;
        }
        .mt-4, .my-4 {
            margin-top: get-vw(1.5rem) !important;
        }
        .mt-5, .my-5 {
            margin-top: get-vw(3rem) !important;
        }
        .mb-1, .my-1 {
            margin-bottom: get-vw(0.25rem) !important;
        }
        .mb-2, .my-2 {
            margin-bottom: get-vw(0.5rem) !important;
        }
        .mb-3, .my-3 {
            margin-bottom: get-vw(1rem) !important;
        }
        .mb-4, .my-4 {
            margin-bottom: get-vw(1.5rem) !important;
        }
        .mb-5, .my-5 {
            margin-bottom: get-vw(3rem) !important;
        }
        .mb-n5, .my-n5 {
            margin-bottom: get-vw(-3rem) !important;
        }
        .ml-3, .mx-3 {
            margin-left: get-vw(1rem) !important;
        }
        .ml-n5, .mx-n5 {
            margin-left: get-vw(-3rem) !important;
        }
        .mr-3, .mx-3 {
            margin-right: get-vw(1rem) !important;
        }
        .mr-n5, .mx-n5 {
            margin-right: get-vw(-3rem) !important;
        }
        .pt-2, .py-2 {
            padding-top: get-vw(0.5rem) !important;
        }
        .p-3 {
            padding: get-vw(1rem) !important;
        }
        .pt-3, .py-3 {
            padding-top: get-vw(1rem) !important;
        }
        .pt-4, .py-4 {
            padding-top: get-vw(1.5rem) !important;
        }
        .pt-5, .py-5 {
            padding-top: get-vw(3rem) !important;
        }
        .pb-2, .py-2 {
            padding-bottom: get-vw(0.5rem) !important;
        }
        .pb-3, .py-3 {
            padding-bottom: get-vw(1rem) !important;
        }
        .pb-4, .py-4 {
            padding-bottom: get-vw(1.5rem) !important;
        }
        .pb-5, .py-5 {
            padding-bottom: get-vw(3rem) !important;
        }
        .pl-4, .px-4 {
            padding-left: get-vw(1.5rem) !important;
        }
        .pl-5, .px-5 {
            padding-left: get-vw(3rem) !important;
        }
        .pr-4, .px-4 {
            padding-right: get-vw(1.5rem) !important;
        }
        .pr-5, .px-5 {
            padding-right: get-vw(3rem) !important;
        }

    // GRID SPACING SMALL OVERRIDE
        .mb-sm-0, .my-sm-0 {
            margin-bottom: 0 !important;
        }

    // GRID SPACING MEDIUM OVERRIDE
        .pt-md-0, .py-md-0 {
            padding-top: 0 !important;
        }
        .pb-md-0, .py-md-0 {
            padding-bottom: 0 !important;
        }
        .mb-md-0, .my-md-0 {
            margin-bottom: 0 !important;
        }
        .mb-md-4, .my-md-4 {
            margin-bottom: 1.5rem !important;
        }

    // GRID SPACING LARGE OVERRIDE
        .p-lg-0 {
            padding: 0 !important;
        }
        .p-lg-5 {
            padding: get-vw(3rem) !important;
        }
        .pt-lg-0, .py-lg-0 {
            padding-top: 0 !important;
        }
        .pb-lg-0, .py-lg-0 {
            padding-bottom: 0 !important;
        }
        .pt-lg-3, .py-lg-3 {
            padding-top: get-vw(1rem) !important;
        }
        .pt-lg-4, .py-lg-4 {
            padding-top: get-vw(1.5rem) !important;
        }
        .pt-lg-5, .py-lg-5 {
            padding-top: get-vw(3rem) !important;
        }
        .pb-lg-1, .py-lg-1 {
            padding-bottom: get-vw(0.25rem) !important;
        }
        .pb-lg-3, .py-lg-3 {
            padding-bottom: get-vw(1rem) !important;
        }
        .pb-lg-4, .py-lg-4 {
            padding-bottom: get-vw(1.5rem) !important;
        }
        .pb-lg-5, .py-lg-5 {
            padding-bottom: get-vw(3rem) !important;
        }
        .pr-lg-0, .px-lg-0 {
            padding-right: 0 !important;
        }
        .pr-lg-4, .px-lg-4 {
            padding-right: get-vw(1.5rem) !important;
        }
        .pr-lg-5, .px-lg-5 {
            padding-right: get-vw(3rem) !important;
        }
        .pl-lg-1, .px-lg-1 {
            padding-left: get-vw(0.25rem) !important;
        }
        .pl-lg-5, .px-lg-5 {
            padding-right: get-vw(3rem) !important;
        }
        .m-lg-0 {
            margin: 0 !important;
        }
        .mb-lg-0, .my-lg-0 {
            margin-bottom: 0 !important;
        }
        .mt-lg-5, .my-lg-5 {
            margin-top: get-vw(3rem) !important;
        }
        .mb-lg-4, .my-lg-4 {
            margin-bottom: get-vw(1.5rem) !important;
        }
        .mb-lg-5, .my-lg-5 {
            margin-bottom: get-vw(3rem) !important;
        }
        .mt-lg-n1, .my-lg-n1 {
            margin-top: get-vw(-0.25rem) !important;
        }
        .ml-lg-3, .mx-lg-3 {
            margin-left: get-vw(1rem) !important;
        }
        .mr-lg-3, .mx-lg-3 {
            margin-right: get-vw(1rem) !important;
        }
        .ml-lg-auto, .mx-lg-auto {
            margin-left: auto !important;
        }
        .mr-lg-auto, .mx-lg-auto {
            margin-right: auto !important;
        }

}
