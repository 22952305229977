
.cp-popup {
    .cp-popup-close-button {
        right: 0;
        left: auto;
        top: 0;
        border-radius: 0;
        background: $brown;
        border: none;
        width: 65px;
        height: 65px;
        &::before, 
        &::after {
            left: 19px;
            top: 30px;
            width: 30px;
            height: 2px;
        }
    }
}


/*********************/
/*        RWD        */
/*********************/

// @media screen and (max-width: 1140px) {
//     .cp-popup {
//         .cp-popup-close-button {
//             width: get-vw(65, 1140);
//             height: get-vw(65, 1140);
//             &::before, 
//             &::after {
//                 left: get-vw(19, 1140);
//                 top: get-vw(30, 1140);
//                 width: get-vw(30, 1140);
//                 height: get-vw(2, 1140);
//             }
//         }
//     }
// }

@include media-breakpoint-down(xs) {
    .cp-popup {
        .cp-popup-close-button {
            width: 40px;
            height: 40px;
            &::before,
            &::after {
                width: 24px;
                height: 2px;
                top: 19px;
                left: 9px;
            }
        }
    }
}