@import "../_prestashop/components/newsletter.scss";

.block_newsletter {
  // padding-top: 2.5rem;
  position: relative;
  max-width: 60rem;
  .icon {
    border: 1px $block-border-color solid;
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    font-size: 23px;
    font-weight: 600;
    top: -20px;
    left: 50%;
    background: $white;
    transform: translateX(-50%);
    width: auto;
  }
  #block-newsletter-label {
    font-size: 1rem;
    text-align: center;
    h3 {
      font-weight: 500;
    }
    p {
      max-width: 600px;
      width: 100%;
      margin: auto;
    }
  }
  form {
    label {
      padding-left: 20px;
      text-transform: uppercase;
    }
    input[type="email"] {
      padding-left: 20px;
      font-weight: 300;
      &:focus {
        padding-left: 20px;
        border: 1px $brown solid;
        outline: 0;
      }
    }
    // .btn {
    //   min-width: 85px;
    // }
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .block_newsletter {
    form .btn {
      width: 85px;
      min-width: 85px;
      font-size: 12px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .block_newsletter {
    margin-bottom: get-vw(0.625rem);
    font-size: get-vw($font-size-sm);
    max-width: get-vw(60rem);
    .icon {
      padding: get-vw(10px);
      font-size: get-vw(23px);
      top: get-vw(-20px);
    }
    form {
      label {
        padding-left: get-vw(20);
      }
      input {
        height: get-vw(42);
      }
      input[type="email"] {
        padding-left: get-vw(20);
        &:focus {
          padding-left: get-vw(20);
        }
      }
    }
    #block-newsletter-label {
      font-size: get-vw(1.3125rem);
      line-height: get-vw(1.75rem);
    }
  }
}
