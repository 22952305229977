/*** IBIF ***/
// Generates utility classes automatically
// Based on Bootstrap 5 Utility API - https://getbootstrap.com/docs/5.0/utilities/api/
// Place definitions here, api is placed in separate file (don't edit api file)

@import "./utility_api";

/*******************/
// VARIABLES
/*******************/

$font-sizes: (
    base: $base-font-size,
    xs: $base-font-size * 0.75,
    sm: $base-font-size * 0.875,
    lg: $base-font-size * 1.125,
    xl: $base-font-size * 1.25,
    xxl: $base-font-size * 1.5,
    xxxl: $base-font-size * 2
);
$position-values: (
    0: 0,
    50: 50%,
    100: 100%
) !default;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
);
$negative-spacers: negativify-map($spacers);
$enable-important-utilities:  true !default;
$hover-colors: (
    'black': $black, 
    'brown': $brown, 
    'white': $white,
    'gray-lighter': $whiteSmoke
);


/*******************/
// RULES
/*******************/

$utilities: (

    /*********************/
    // Bootstrap 5 rules
    /*********************/
    
    "top": (
        property: top,
        values: $position-values
    ),
    "bottom": (
        property: bottom,
        values: $position-values
    ),
    "start": (
        property: left,
        class: start,
        values: $position-values
    ),
    "end": (
        property: right,
        class: end,
        values: $position-values
    ),
    "translate-middle": (
        property: transform,
        class: translate-middle,
        values: (
            null: translate(-50%, -50%),
            x: translateX(-50%),
            y: translateY(-50%),
        )
    ),
    "opacity": (
      property: opacity,
      values: (
        0: 0,
        25: .25,
        50: .5,
        75: .75,
        100: 1,
      )
    ),
    "pointer-events": (
      property: pointer-events,
      class: pe,
      values: none auto,
    ),
    
    /*********************/
    // Ibif rules
    /*********************/

    // Ibif custom font sizes
    "font-sizes-scaled-off-font-size": (
        responsive: true,
        property: font-size,
        class: ib-font,
        values: $font-sizes
    ),
    // Ibif custom bg color on hover
    "background-hover-colors": (
        property: background-color,
        class: ib-bg,
        state: hover focus,
        values: $hover-colors
    ),
    // Ibif custom text color on hover
    "text-hover-colors": (
        property: color,
        class: ib-text,
        state: hover focus,
        values: $hover-colors
    ),
);


/*******************/
// PROCESS
/*******************/

// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {

    // Generate media query if needed
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    
        // Loop over each utility property
        @each $key, $utility in $utilities {
            // The utility can be disabled with `false`, thus check if the utility is a map first
            // Only proceed if responsive media queries are enabled or if it's the base media query
            @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
                @include generate-utility($utility, $infix);
            }
        }
    }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
    .ib-font-xs {
        font-size: get-vw(map-get($font-sizes, "xs")) !important;
    }
    .ib-font-sm {
        font-size: get-vw(map-get($font-sizes, "sm")) !important;
    }
    .ib-font-lg, .ib-font-lg-lg {
        font-size: get-vw(map-get($font-sizes, "lg")) !important;
    }
    .ib-font-xl, .ib-font-lg-xl {
        font-size: get-vw(map-get($font-sizes, "xl")) !important;
    }
    .ib-font-xxl, .ib-font-lg-xxl {
        font-size: get-vw(map-get($font-sizes, "xxl")) !important;
    }
}
