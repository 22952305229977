.ib-col-override {
    flex: 0 0 100%;
    max-width: 100%;
}
#checkout {
    // a {
    //     &:not(:hover) {
    //         color: inherit;
    //     }
    // }
    .title-heading {
        margin-left: 0;
        margin-right: 0;
        span {
            color: $breadcrumb-color;
        }
    }
    div#onepagecheckoutps #form_login span.form-error{
        bottom:-5px;
    }
    #opc_modal.Logowanie{
        .modal-body{
            padding-bottom: 10px;
        }
    }
    #furgonetka-machine .furgonetka-machine{
        background-color: #f5f5f5;
        color:#000;
        border:none;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
    }
    .delivery-option,
    .payment-option {
        border-radius: 10px;
        background-color: $whiteSmoke;
        // border-radius: $input-border-radius;
        margin-bottom: get-vw(10);
        line-height: 1.2;
        // > label {
        &:hover {
            background-color: $input-border-color;
        }
        .ib-option-label {
            text-transform: none;
            font-size: inherit;
            cursor: pointer;
        }
    }
    // .block-additional-info {
    //     background-color: $whiteSmoke;
    // }
    .col-md-3.form-control-label,
    .col-md-4.form-control-label,
    .col-md-8.opc_field_right {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .cart-summary-line {
        &::after {
            content: none;
        }
    }
    // .product-line-grid {

    // }
    .payment-option {
        img {
            max-height: none !important;
            max-width: 100% !important;
            margin: 0;
        }
        > div {
            margin-right: auto !important;
        }
    }
    .ib-label-abs::before {
        content: '';
        // background: pink;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
    .additional-information {
        margin: 2.5rem 0 !important;
    }
    .additional-information:empty {
        display: none !important;
    }
    .definition-list dl {
        display: grid;
        grid-template-columns: auto auto;
        gap: 5px;
        dd, dt {
            // flex: 0 0 49%;
            flex: none;
            margin: 0;
        }
    }
    .type-checkout-options label,
    .checkbox label {
        padding-left: 0;
    }
    .form-control-label.required {
        > .name::after {
            content: "*";
            color: #ff3f3f;
            font-size: 20px;
            position: absolute;
            right: 0;
            // margin-right: -1rem;
            margin-right: -0.75rem;
            top: 100%;
        }
        &.ets_checkinput::after {
            top: 0;
        }
    }
    .product-line-grid-left {
        width: 85px;
    }
    // .product-line-grid-body.col {
        .label {
            font-size: inherit;
            font-weight: inherit;
        }
    // }
    .product-line-grid-right, 
    .ib-cart-item-li {
        .value {
            font-weight: 700;
            padding: 0 15px;
            width: 160px;
            margin-left: 3.5rem;
            i {
                padding: 3px;
            }
        }
        .product-customization-line {
            .value {
                margin-left: 0;
            }
        }
    }
    .product-price {
        color: inherit;
    }
    .ib-cart-item-li {
        // border-top: 1px solid $block-border-color;
        border-bottom: 1px solid $block-border-color;
        // padding: 1.25rem 0;
    }
    // .product-line-info {
    //     max-width: 220px;
    // }
    #message {
        height: 300px;
        padding: 10px 15px;
        resize: none;
    }
    // label.ets_checkinput,
    // #conditions-to-approve li {

    // }
    label.ets_checkinput{
        display: flex;
    }
    label.ets_checkinput, 
    #conditions-to-approve label {
    
        align-items: center;
        height: 100%;
        font-weight: 600;
        text-transform: none;
        padding: 0;
        a {
            text-decoration: underline;
        }
    }
    .type-checkout-option/* .create */ {
        position: relative;
    }
    .block-promo {
        .promo-name {
            .cart-summary-line {
                display: grid;
                grid-template-columns: 1fr 250px 50px;
                .label {
                    font-weight: 500;
                    color: $gray-dark;
                }
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: $brown;
                }
            }
        }    
    }
}
.block-additional-info label {
    text-transform: none;
    font-weight: inherit;
    font-size: inherit;
    padding-left: 1rem;
    &.form-control-label {
        margin-bottom: 1rem;
    }
}
.block-additional-info .custom-radio {
    margin-right: 1rem;
}
.block-onepagecheckout {
    margin-bottom: 30px;
    border-radius: $input-border-radius;
    .form-ok:before {
        top: 50%;
        margin-top: -7px;
        width: 6px;
        height: 13px;
        right: 28px;
        // border-width: 2px;
    }
    .form-error:before {
        // top: 50%;
        // margin-top: -21px;
        // line-height: 28px;
        top: 12px;
        line-height: 1;
        font-size: 28px;
        right: 22px;
        z-index: 1;
    }
}
.block-onepagecheckout .cart-summary-line {
    margin-bottom: 9px;
}
.block-onepagecheckout .form-group {
    margin-bottom: 10px;
}
.ets_opc_select span.ets_opc_select_arrow {
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    display: flex;
    justify-content: center;
}
.ets_opc_select span.ets_opc_select_arrow svg {
    fill: $black;
    width: 18px;
}
.onepagecheckout-left .block-onepagecheckout .col-md-8 {
    padding-right: 0;
}
.block-additional-info {
    .required::after {
        content: none !important;
    }
}
.type-checkout-options {
    gap: 0.5vw;
    li {
        margin: 0;
        padding: 0;
    }
    input:checked + span {
        color: $brown;
    }
}
.onepagecheckout-left .cart-item {
    text-align: left;
}
#delivery-addresses > .form-group {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
#checkout div#onepagecheckoutps #onepagecheckoutps_step_one div.form-group label{
    float:unset;
}
#checkout .form-control-label.required {
    &::after{
        content:"";
    }

    > .name::after{
        position: relative;
    }
}
.img-thumbnail{
    border:none;
    background-color: transparent;
}

#onepagecheckoutps {
    .title-heading{
        margin-bottom: 20px;
        padding:10px 0;
    }
    .invoice.collapsed i.ets_checkbox {
        background-color: $white;
        
    }
    .invoice:not(.collapsed) i.ets_checkbox {
        background-color: $primary-color;
        border-color: $primary-color;
        -webkit-transform: none;
        transform: none;
    }
    .ets_checkinput input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        margin: 4px 0 0!important;
        width: 16px;
        height: 16px;
    }
    i.ets_checkbox {
        display: inline-block;
        border-radius: 7px;
        @extend %custom-checkbox;
    }
    input:checked + i.ets_checkbox {
        @extend %custom-checkbox-checked;
    }
    #address_card_new_content {
        .btn {
            line-height: 50px;
        }        
    }
    #panel_address_invoice,
    #delivery_address_container {
        .selected {
            i {
                color: $green;
            }
        }
    }
    .container_card {
        border: 1px solid $block-border-color;
        border-radius: $input-border-radius;
        cursor: pointer;
    }
    #opc_show_login {
        cursor: pointer;
        // text-decoration: underline;
        @extend .ib-transition-all;
    }
    #opc_login {
        input {
            &.error {
                margin-bottom: 25px;
            }
        }
    }
}
#customer-login {
    padding: 0 15px 15px;
}
.block-additional-info .input-radios {
    padding-top: 10px;
}
.block-shipping .block-content,
.block-shopping-cart {
    padding-left: 15px;
    padding-right: 15px;
}

#form_ets_onepagecheckout .cart-products-count {
    top: 40px;
}

#display_cart_vouchers {
    .code {
        cursor: pointer;
    }
}
div#onepagecheckoutps div#onepagecheckoutps_step_review #order-detail-content .order_total_items .item_total .price.price-discount{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(xs) {
    
    // div#onepagecheckoutps #onepagecheckoutps_step_review i.cart_quantity_delete{
    //     position: absolute;
    //     right: -15px;
    // }
    .type-checkout-options li + li {
        margin-top: 0.5rem;
    }
    .opc_forgot_submit .btn {
        width: 100%;
        margin-top: 10px;
    }
    .block-additional-info .input-radios label {
        display: block;
        margin-bottom: 0 !important;
        + label {
            margin-top: 20px !important;
        }
    }
}

// @include media-breakpoint-only(md) {
@include media-breakpoint-between(sm, md) {
    .opc_forgot_submit .btn {
        width: auto;
    }
}
@include media-breakpoint-down(md) {
    
    
    #onepagecheckoutps .choose-option button{
        margin:10px 0;
        font-weight: 700;
    }
    #checkout div#onepagecheckoutps div#onepagecheckoutps_step_two #shipping_container .delivery_option_logo img{
        max-width: 100px;
        min-width: 100px;
    }
    #checkout .delivery-option .carrier-price{
        font-size: 22px;
    }
    
    .h6.carrier-name {
        margin: 0.5rem 0 0;
    }
    .ib-option-logo {
        max-width: 130px;
        margin: auto;
    }
    #form_ets_onepagecheckout {
        // padding-left: 20px;
        // padding-right: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .block-customer,
    .block-address,
    .block-shipping,
    .block-payment {
        border: 1px solid $block-border-color;
        padding: 20px !important;
    }
    .block-customer,
    .block-address,
    .block-shipping {
        margin-bottom: 70px;
    }
    #checkout {
        .title-heading {
            padding: 0 0 0 10px;
            .col {
                padding: 0;
            }
            h2 {
                font-size: 20px;
                font-weight: 700;
            }
            span {
                display: none;
            }
        }
        // .col-md-3.form-control-label, 
        // .col-md-4.form-control-label, 
        .col-md-8.opc_field_right {
            padding: 0;
        }
        .col-md-4.form-control-label {
            margin: 0.25rem 0 0;
        }
        .delivery-option,
        .payment-option {
            label {
                &:not(.ib-option-label) {
                    padding-left: 0;
                }
            }
        }    
    }
    .onepagecheckout-left {
        padding-left: 0;
        padding-right: 0;
    }
    .block-additional-info {
        margin: 0px -20px -20px;
        padding-top: 1rem;
        padding-bottom: 25px;
        label {
            padding: 0;
        }
    }
    .delivery-options {
        margin: 0 -15px;
    }
    #checkout {
        .delivery-option, 
        .payment-option {
            display: block !important;
            text-align: center;
            .custom-radio {
                margin: 0;
            }
            label {
                text-align: inherit;
            }
            img {
                max-height: 100px !important;
            }
        }
        .payment-options,
        .delivery-options {
            > div + div {
                margin-top: 1rem;
            }
        }
    }
    .ib-option-label > .row {
        display: block;
    }
    .block-additional-info {
        .input-radios label {
            font-size: 1rem;
        }
        label.form-control-label {
            font-size: 1.25rem;
            margin-bottom: 2rem;
            padding-left: 10px;
        }
    }
    .block-shopping-cart > .title-heading {
        display: none;
    }
    #checkout .cart-summary-line .label {
        text-align: left;
        padding: 0;
    }
    #checkout .cart-summary-line .value {
        padding: 0;
        margin: 0;
        width: auto;
    }
    .cart-detailed-totals > div {
        width: 100%;
        padding-top:15px;
    }
    .cart-detailed-totals .card.cart-summary {
        order: -1;
    }
    #checkout .ib-cart-item-li {
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        margin: 0;
        // py-2 (but we cant use it because desktop has custom paddings)
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        align-items: center;
    }
}   
#checkout div#onepagecheckoutps #onepagecheckoutps_step_review .product-price, #label_delivery_message div{
    font-weight: 700;
}
#div-gift{
    width: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
   
    
    align-items: center;
    
    i.icon{
        color:$primary-color;
    }
    .gift-price{

        display: flex;
        justify-content: center;
        align-items: center;

    }
}
#field_customer_rodzaj-klienta{
    .ib-bg-whitesmoke{
        border-radius: 10px;
    }

    label{
        text-transform: none;
    }
}
div#onepagecheckoutps #onepagecheckoutps_step_one .fields_required > span{
    font-style:unset;
}
div#onepagecheckoutps #onepagecheckoutps_step_one .form-group .form-control{
    border-radius: 7px!important;
}
body>.modal.Logowanie{
    display: none!important;
}
div#onepagecheckoutps #remaining_amount_free_shipping-text > span{
    color:$primary-color;
    text-transform: none!important;
}
div#onepagecheckoutps #remaining_amount_free_shipping-text{
    font-weight: bold;
    text-transform: uppercase;
    
}
#remaining_amount_free_shipping  .progress{
    height: 0.75rem;
    .progress-bar{
        background-color: $primary-color!important;
    }
}

@include media-breakpoint-up(lg) {
    #remaining_amount_free_shipping  .progress{
        height: get-vw(10);
    }

    div#onepagecheckoutps #remaining_amount_free_shipping #remaining_amount_free_shipping-text{
        font-size: get-vw(15);
        margin-bottom: get-vw(15);
        
    }
    div#onepagecheckoutps #remaining_amount_free_shipping-text{
        font-size: get-vw(15);
        
    }
    div#onepagecheckoutps #onepagecheckoutps_step_review i.cart_quantity_delete{
        margin-left: 8px;
    }
    #checkout label.ets_checkinput, #checkout #conditions-to-approve label{
        font-size: get-vw(15);
    }
    #checkout .delivery-option .carrier-price{
        font-size: get-vw(14);
    }
    #checkout div#onepagecheckoutps div#onepagecheckoutps_step_review #order-detail-content .order_total_items .item_total .price{
        font-size: get-vw(18);
    }
    #checkout .delivery-option .ib-option-label .carrier-delay{
        margin-top: 0.45vw;
        display: block;
    }
    #div-gift{
        display: grid;
        grid-template-columns: get-vw(110) auto auto;
        padding:get-vw(25) 0;
        i.icon{
            padding:0 get-vw(35);
            font-size: get-vw(40);
        }
        .gift-price{
            height: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: get-vw(14);
            padding-right: get-vw(25);
            margin-bottom: get-vw(5);
        }
        label{
            margin-top: get-vw(20);
            font-size: get-vw(16);
            .ets_checkbox{
                margin-right: get-vw(10);
            }
        }
    }
    #field_customer_rodzaj-klienta{
        label{
            font-size: get-vw(18);
        }
        .custom-radio{
            margin-right: 1.2vw;
        }
    }
    #onepagecheckoutps {
        .product-line-grid-body.cart_description{
            padding-right: get-vw(75);
        }
        .title-heading{
            margin-bottom: get-vw(20);
            padding:get-vw(5) 0;
        }
        #onepagecheckoutps_step_one{
            padding-right: 0.5vw;
        }
        .choose-option{
            display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vw;
            border-bottom: 1px solid #e7e7e7;
            button{
                font-weight:700;
                height: get-vw(45);
                width: 100%;
            }
        }
        #address_card_new_content {
            .btn {
                line-height: get-vw(50px);
            }        
        }
        .modal-body {
            padding: get-vw(3.125rem) get-vw(1.875rem);
        }
    }
    .ib-option-price {
        -webkit-box-flex: 0;
        flex: 0 0 30%;
        max-width: 30%;
    }
    .ib-option-logo {
        width: get-vw(105);
    }
    .ib-checkout-options {
        margin-top: -11px;
    }
    .ets_opc_select span.ets_opc_select_arrow {
        width: get-vw(50);
    }
    .ets_opc_select span.ets_opc_select_arrow svg {
        width: get-vw(18);
    }
    .ib-checkout-options {
        border-bottom: 1px solid $block-border-color;
        border-top: 1px solid $block-border-color;
    }
    .ib-counter-section {
        counter-reset: ib-section;
        i.ib-counter {
            font-style: normal;
            font-weight: 500;
            width: get-vw(40);
            line-height: get-vw(38);
            border: 1px solid $primary-color;
            // background: $whiteSmoke;
            &::before {
                counter-increment: ib-section;
                content: counter(ib-section);
            }
        }
    }
    #checkout {
        .cart-summary-line .label{
            padding-right: get-vw(75);
            font-size: get-vw(18);
        }
        .cart-products-count {
            position: absolute;
        }
        .title-heading {
            border-top: 1px solid $block-border-color;
            border-bottom: 1px solid $block-border-color;
            h2 {
                font-size: get-vw(20);
                font-weight: 700;
            }
        }
        .product-line-grid-left {
            width: get-vw(85);
        }
        .ib-cart-item-li .value {
            font-size: get-vw(18);
        }
        .product-line-grid-right, 
        .ib-cart-item-li .value {
            padding: 0 get-vw(15);
            width: get-vw(140);
            margin-left: 0;
  
            i {
                padding: get-vw(3);
            }
        }
        .ib-cart-item-li {
            padding: get-vw(1rem) 0;
        }
        .form-control-label.required {
            > .name::after {
                font-size: get-vw(20);
                margin-right: get-vw(-1rem);
            }
        }
        #message {
            height: get-vw(90);
            padding: get-vw(10) get-vw(15);
        }
    }
    #customer-login {
        padding: 0 get-vw(15) get-vw(15);
    }
    .block-additional-info label {
        padding-left: get-vw(1rem);
        &.form-control-label {
            margin-bottom: get-vw(1rem);
        }
    }
    .block-additional-info .custom-radio {
        margin-right: get-vw(1rem);
    }
    .block-additional-info .input-radios {
        padding-top: get-vw(10);
    }
    .block-onepagecheckout {
        margin-bottom: get-vw(30);
        .form-ok:before {
            margin-top: get-vw(-7);
            width: get-vw(6);
            height: get-vw(13);
            right: get-vw(28);
            border-width: get-vw(2);
        }
        .form-error:before {
            // margin-top: get-vw(-21);
            // line-height: get-vw(28);
            top: get-vw(12);
            font-size: get-vw(28);
            right: get-vw(22);
        }
    }
    .block-onepagecheckout .cart-summary-line {
        margin-bottom: get-vw(9);
    }
    .block-onepagecheckout .form-group {
        margin-bottom: get-vw(10);
    }
    #delivery-addresses > .form-group {
        margin-top: get-vw(1.5rem);
        margin-bottom: get-vw(1.5rem);
    }
    .block-shipping .block-content,
    .block-shopping-cart {
        padding-left: get-vw(15);
        padding-right: get-vw(15);
    }
}