.ets_mm_megamenu.layout_layout1 {
    padding-left: 5rem;
    padding-right: 5rem;
    .ets_mm_megamenu_content {
        // width: get-vw(1600);
        background: none;
        margin: auto;
        border: none;
    }
    .container {
        width: 100%;
        max-width: none;
    }
    .mm_menus_li {
        border-right: none;
    }
    .mm_menus_li:nth-child(2) {
        border-left: none;
    }
    .mm_menus_li:last-child {
        border: none;
    }
    // .mm_menus_li > a {
    .mm_menus_ul a {
        @extend .ibe_text-trigger;
        text-transform: none;
        font-weight: 500;
    }
    .mm_menus_ul .close_menu {
        display: none !important;
    }
    .mm_menu_content_title {
        font-weight: 900;
        
        line-height: 110%;
        padding: 0 0 get-vw(6);
        transition: 0.3s;
        &:hover{
            color:$primary-color;
        }
    }
    // &.scroll_heading {
    //     box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
    // }
    body &::before {
        @extend .ib-overlay;
    }
    .mm_arrow {
        display: none;
    }
    #header & .ets_mm_block_content a {
        color: $black;
        --color: #{$primary-color};
        line-height: inherit;
        // font-weight: lighter;   //looked bad
        &:hover {
            color: $primary-color;            
        }
    }
    .ets_mm_block_content ul li a {
        @extend .ib-transition-all;
        @extend .ibe_text-underline;
        &::before {
            content: none;
        }
    }
    .mm_blocks_li {
        margin-bottom: 0;
    }
    .ets_mm_categories li {
        text-transform: uppercase;
        // margin: 0.25rem 0;
        margin: get-vw(10) 0;
        line-height: 120%;
    }
    .h2 {
        // font-size: 2.5rem;
        font-size: get-vw(40);
        text-transform: none;
        font-weight: lighter;
        &::after {
            content: '';
            display: block;
            border-bottom: 1px solid;
            width: 15px;
            margin: 0.5rem 0.15rem;
        }
    }
    .h3 {
        text-transform: uppercase;
        color: $primary-color;
        font-size: get-vw(39px);
        font-weight: 600;
    }
    .h5 {
        // @extend .ib-font-lg;
        font-size: get-vw(18);
        // @extend .mb-4;
        // margin-bottom: 2rem;
        margin-bottom: get-vw(2rem);
        text-transform: uppercase;
    }
    .mm_columns_ul {
        // padding: 5rem 6rem 4rem 30%;
        // font-size: inherit;
        // padding: get-vw(5rem) get-vw(6rem) get-vw(4rem);
        padding: 0;
        font-size: 16px;
        // &::before {
        //     content: '';
        //     left: -100%;
        //     right: -100%;
        //     top: -1px;
        //     bottom: -50px;
        //     background: $white;
        //     height: auto;
        //     border-top: 1px solid $gray-light;
        //     z-index: -1;
        //     @extend .shadow;
        // }
    }
    .discount-percentage,
    .product-list-reviews,
    .product_combination
    {
        display: none;
    }
}
.ets_mm_megamenu.hook-default {
    margin: 0 !important;
}
.ybc-menu-toggle.ybc-menu-btn {
    display: none !important;
}
// .ybc-menu-toggle i.icon-bar {
//     margin: 2px 3px 4px 0;
//     width: 25px;
// }
.layout_layout1.transition_floating .mm_menus_ul {
    max-width: 100%;
    // overflow-x: hidden;
}
.ib-menu-header, 
.ib-menu-close {
    height: 60px;
    &.with-border-bottom {
        border-bottom: 1px solid $block-border-color;
        height: 90px;
    }
}

.ib-menu-language-currency {
    overflow: unset !important;
}

#_mobile_currency_selector2,
#_mobile_language_selector2 {
    display: block;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    z-index: 200000;
    ul {
        li {
            // padding: 1rem;
            a {
                border: 0;
            }
            &.current {
                a {
                    color: $primary-color;
                }
            }
        }
    }    
}
#_mobile_currency_selector2 {
    border-bottom: 1px solid $block-border-color;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    #header .ets_mm_megamenu.layout_layout1 .mm_menus_ul .ib-menu-header{
        color:$primary-color;
        font-weight: 700;
          a{
            color:$primary-color;
          }
      }
    .ets_mm_megamenu.layout_layout1 .mm_menu_content_title{
        font-weight: 500;
    }
    .ets_mm_megamenu.layout_layout1 {
        &::before {
            content: none;
        }
        // main container
        .mm_menus_ul {
            background: $white;
            overflow: hidden;
            // top: 68px;
            box-shadow: 0 -2px 10px #e7e7e7;
            color: $black;
        }
        .mm_menus_li > a {
            font-size: inherit;
            color: inherit !important;
        }
        /**************************/
        // mobile "feature"
        /**************************/
        .ib-subcategories {
            // position: absolute;
            // display: none;
            position: fixed;
            // left: 100%;
            // margin-left: 100%;
            right: 100%;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background: $white;
            z-index: 2;
            // transition: left $tspeed-300 ease;
            transition: right $tspeed-300 ease;
            // .ib-return {
            //     display: block;
    
            // }
            > .mm_columns_ul {
                margin: 0;
                display: block;
                position: static;
                float: left;
                padding-bottom: 80px;
            }
        }
        .opened ~ .ib-subcategories {
            // display: block;
            // left: 0;
            // margin-left: 0;
            right: 0;
        }
        .fibicon-angle-small-up {
            transform: rotate(90deg);
            display: inline-block;
            font-weight: 600;
        }
        li.has-sub {
            position: static;
        }
        span.arrow {
            display: block;
            width: 50px;
            line-height: 52px;
            margin-bottom: -52px;
            margin-left: auto;
            text-align: center;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }
        .ets_mm_categories {
            display: block !important;
            height: auto !important;
            padding: 0 !important;
        }
    }
    .ib-return {
        padding: 0;
        border: none;
        background: none;
        text-transform: inherit;
        &, &.hidden {
            display: inline-block;
        }
    }
    li.mm_blocks_li {
        padding: 0;
    }
    .mm_menus_ul li > a {
        padding: 1rem;
        display: block;
        border-bottom: 1px solid $block-border-color;
        text-transform: none;
        z-index: 0 !important;
    }
    .ets_mm_categories li {
        float: none !important;
    }
    .mm_columns_ul {
        border: none !important;
    }
    .mm_blocks_ul {
        visibility: visible;
    }

    /************************/
    // LAYOUT 1 + 2
    /************************/
    .mm_sub_align_full.layout1,
    .mm_sub_align_full.layout2 {
        li.column_size_9 {
            display: none;
        }
    }
    /************************/
    // LAYOUT 1
    /************************/    
    .mm_sub_align_full.layout1 {
        .mm_columns_li:first-child {
            display: none;
        }
    }
    /************************/
    // LAYOUT 2
    /************************/
    .mm_sub_align_full.layout2 {
        .column_size_9 + .column_size_3 {
            display: none;
        }
    }
}
@include media-breakpoint-up(lg) {
    .mm_block_type_html .collection{
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.94vw;
        color:#000;
    }
    .ets_mm_megamenu_content_content, .hook-default .ets_mm_megamenu_content .container, .ets_mm_megamenu.layout_layout1 .ets_mm_megamenu_content{
        height: 100%;
    }
    .ets_mm_megamenu.layout_layout1 .mm_menus_li{
        display: flex;
        align-items: center;
    }
    .ets_mm_megamenu.layout_layout1 .ets_mm_megamenu_content{
        margin-left: get-vw(70);
        margin-right: get-vw(15);
    }
    .ets_mm_megamenu.layout_layout1.scroll_heading{
        height:unset;
    }
    .ets_mm_megamenu.layout_layout1 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;   
        
        padding-left: 5rem;
        padding-right: 5rem;
        // .ets_mm_megamenu_content {
        //     width: get-vw(1600);
        // }
        &.scroll_heading {
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
        }
        .mm_menus_li > a {
            font-size: get-vw(19.5);
            padding: get-vw(28) get-vw(19) get-vw(20);
        }
        ul.mm_menus_ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
        .mm_columns_ul {
            padding: get-vw(5rem) get-vw(6rem) get-vw(4rem);
            font-size: get-vw(16);
            &::before {
                content: '';
                left: 0;
                right: 0;
                top: -1px;
                bottom: -50px;
                background: $white;
                height: auto;
                border-top: 1px solid $gray-light;
                z-index: -1;
                // @extend .shadow;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important
            }
        }
        .mm_blocks_li {
            margin-bottom: get-vw(50);
        }
        // .mm_blocks_ul a {
        //     text-transform: uppercase;
        // }
    }
    .ets_mm_block_content .has-sub > a:after {
        content: none !important;
    }
    .ets_mm_block_content ul li:hover > ul {
        display: none;
    }
    .ib-menu-header {
        display: none;
    }

    /************************/
    // LAYOUT 1 + 2
    /************************/
    .mm_sub_align_full.layout1,
    .mm_sub_align_full.layout2 {
        li.column_size_9 {
            width: 100%;
        }
        li.column_size_3 {
            width: 33.3333%;
        }
    }
    /************************/
    // LAYOUT 1
    /************************/
    .mm_sub_align_full.layout1 {
        .mm_columns_ul {
            padding-left: 30%;
        }
        .mm_columns_li{
            padding-left: 1vw;
        }
        .mm_columns_li:first-child {
            position: absolute;
            top: 4.5rem;
            left: 6.2vw;
            width: 30%;
        }
    }
    /************************/
    // LAYOUT 2
    /************************/
    .mm_sub_align_full.layout2 {
        .mm_columns_ul {
            padding-right: 40%;
            padding-left: 0;
        }
        .column_size_9 + .column_size_3 {
            position: absolute;
            right: get-vw(-4rem);
            top: get-vw(6rem);
            .mm_blocks_li {
                padding: 0;
            }
        }
        .wishlist-button-add {
            position: absolute;
            top: 0;
            right: 0;
        }
        .thumbnail-container .thumbnail {
            float: none;
            width: auto;
            display: block;
            margin: 0 0 get-vw(20px);
            border: none;
        }
        .mm_block_type_product .product-miniature {
            clear: left;
            display: block;
            text-align: center;
            width: get-vw(230);
            margin-left: auto;
            margin-right: get-vw(80);
        }
        .product-title.h3 {
            // @extend .ib-font-lg;
            text-align: center;
            font-size: 0.9375vw;    // ib-font-lg
            font-weight: 500;
            display: block;
            margin-bottom: get-vw(1rem); // mb-3
            a {
                font-weight: inherit;
            }
        }
        .product-miniature {
            color: $black;
            position: relative;
            margin-top: get-vw(-2rem);
            ~ .product-miniature {
                display: none;
            }
        }
        .product-miniature .price,
        .product-miniature .regular-price {
            font-size: 1.04167vw;
            font-weight: 600;
            display: block;
            margin: 0;
            width: 100%;
            line-height: 1.25;  // ib-lh-125
        }
        .product-miniature .price {
            color: $red;
            &:last-child {
                color: inherit;
            }
        }
        .product-miniature .regular-price {
            text-decoration: line-through;
            color: inherit;
            order: -1;
        }
        .product-price-and-shipping {
            display: flex;
            flex-wrap: wrap;
        }
        .h3 {
            margin-top: get-vw(-1.875rem);
            margin-bottom: get-vw(2.875rem);
        }
    }
}