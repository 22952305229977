#module-tfdeletecustomer-deleteform {
  .tfdeletecustomer {
    max-width: 1600px;
    textarea {
      height: 100px;
      padding: 10px 18px;
    }
  }
}

/*********************/
/*        RWD        */
/*********************/
@include media-breakpoint-up(lg) {
  #module-tfdeletecustomer-deleteform {
    .tfdeletecustomer {
      max-width: get-vw(1600);
      textarea {
        height: get-vw(100);
        padding: get-vw(10) get-vw(18);
      }
    }
  }  
}

