
.ls-container.ls-noskin {
    overflow: hidden;
    a.ls-gui-element {
        // @extend .hover\:bg-brown-black;
        @extend .rounded-circle;
        @extend .ib-bg-brown;
        @extend .ib-bg-black-hover;
        @include ibif-icon();
        // background-color: $brown;
        width: get-vw(34);
        height: get-vw(34);
        position: absolute;
        top: 50%;
        right: 5%;
        display: block;
        margin-top: get-vw(-17);
        font-size: get-vw(18);
        text-align: center;
        color: $white;
        // &:hover {
        //     background-color: $black;
        // }
        #ls-global & {
            @extend .ib-transition-all;
            line-height: get-vw(34);
        }
    }
    a.ls-nav-prev {
        @extend .fibicon-arrow-small-left;
        margin-right: get-vw(45);
    }
    a.ls-nav-next {
        @extend .fibicon-arrow-small-right;
    }
    .ls-bottom-nav-wrapper {
        display: block;
    }
    .ls-bottom-slidebuttons {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 50px 80px;
        a {
            @extend .rounded-circle;
            // @extend .hover\:bg-white-black;
            @extend .ib-bg-white;
            @extend .ib-bg-black-hover;
            #ls-global & {
                @extend .ib-transition-all;
            }
            // background-color: $white;
            display: inline-block;
            visibility: visible;
            width: 11px;
            height: 11px;
            margin: 5px;
            // &:hover {
            //     background-color: $black;
            // }
            &.ls-nav-active {
                // background-color: $black;
                @extend .ib-bg-black;
            }
        }
    }
    .btn-primary {
        border-radius: 0 !important;
        line-height: get-vw(48) !important;
        padding: 0 get-vw(32) !important;
        width: auto !important;
        height: auto !important;
        font-size: get-vw(14px) !important;
        text-align: center !important;
        // border-width: 1px !important;
        // border-style: solid;
        border: none !important;
        min-width: 150px;

        // we need to override inline rules
        background-color: $black !important;
        color: $white !important;
        border-color: $black;
        @include btn-hover {
            background-color: $white !important;
            color: $black !important;
        }

        #ls-global & {
            @extend .ib-transition-all;
        }
        i {
            position: relative;
            font-size: get-vw(18);
            margin-left: get-vw(12);
            margin-right: get-vw(-10);
            top: get-vw(3);
        }
    }
    // RULES FOR MINISLIDER (SUBPAGES)
    &.mini {
        .ls-bottom-slidebuttons {
            margin-bottom: 45px;
        }
    }
    &.mobile {
        .ls-bottom-slidebuttons {
            width: 100%;
            margin: 0 0 15px;
            text-align: center;
        }
        .btn-primary {
            line-height: 3rem !important;
            font-size: 0.875rem !important;
            i {
                font-size: 18px;
                position: absolute;
                right: 0;
                top: 50%;
                margin: -9px 7px 0;
            }
        }
    }
}

/* RWD */

@include media-breakpoint-up(lg) {
    .ls-container.ls-noskin {
        .ls-bottom-slidebuttons {
            margin: get-vw(50) get-vw(80);
        }
        &.mini {
            .ls-bottom-slidebuttons {
                margin-bottom: get-vw(45);
            }
        }    
    }
}