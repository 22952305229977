.birthday-box{
    background-image: url("/img/birthday_background.jpg");
    background-size: cover;
    border-radius: 6px;
    label{
        text-transform: none;
        font-weight: 400;
    }
    h2{
        font-weight: 600;
    }
    input{
        &::placeholder {
            color:$gray;
          }
    }
}
.birthday-alert{
    border-radius: 5px;
    padding:15px;
}
.birthday-additional-info{
    font-weight: 400;
}
.datepicker{
    padding:15px !important;
    width: auto;
  }
  .datepicker.dropdown-menu tr{
    display: flex;
    justify-content: space-between;
    td[colspan="7"]{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      // width: auto;
      span{
        float: none;
        width: 23%;
        &.active{
          background: $primary-color;
          text-shadow: none;
          &:hover{
            background: $primary-color;
          }
        }
      }
    }
  }
  .datepicker.dropdown-menu th.dow{
    padding: 0;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .datepicker.dropdown-menu td.day{
    border: 1px solid #C9C9C9;
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    &.active{
      background: $primary-color;
      text-shadow: none;
      border-color: $primary-color;
      &:hover{
        background: $primary-color;
      }
    }
  }
  .datepicker.dropdown-menu th, .datepicker.dropdown-menu td{
    padding: 0;
  
  }
  .datepicker thead{
    tr:first-child{
    margin-bottom: 15px;
      th.prev{
        font-size:25px;
        color: $primary-color;
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: get-vw(30px);
        &:hover{
          background: white;
          color: black;
        }
      }
      th.next{
        font-size:20px;
        color: $primary-color;
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: get-vw(30px);
        &:hover{
          background: white;
          color: black;
        }
      }
    }
  }
  .datepicker th.datepicker-switch{
    padding: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:15px;
    &:hover{
      background: none;
    }
  }
  .datepicker thead tr:first-child th:hover, .datepicker tfoot tr th:hover{
    background: none;
    color: $primary-color;
  }
  
/*********************/
/*        RWD        */
/*********************/
@include media-breakpoint-up(lg) {
    .birthday-alert{
        font-size: 1.4vw;
        padding:get-vw(35);
    }
    .birthday-box{
        margin-bottom: 2vw;
        height: 24.6vw;
        border-radius: get-vw(10);
        .btn-primary{
            height: get-vw(55);
            width:get-vw(230);
        }
        label{
            font-size: get-vw(18);
            padding-bottom: get-vw(7);
        }
        input{
            height: get-vw(70);
        }
        form{
            padding-left: get-vw(65);
            padding-right: get-vw(45);
        }
        h2{
            padding-top: get-vw(100);
            padding-left:get-vw(80);
            padding-bottom: get-vw(15);
        }
        .birthday-additional-info{
            padding-left:get-vw(80);
            padding-bottom: get-vw(40);
        }
    }
    .datepicker.dropdown-menu th.dow{
        width: get-vw(30px);
        height: get-vw(30px);
    }
    .datepicker.dropdown-menu td.day{
        width: get-vw(30px);
        height: get-vw(30px);
    }
    .datepicker thead{
        margin-bottom: get-vw(15px);
        th.prev{
            font-size: get-vw(25px);
            width: get-vw(30px);
            height: get-vw(25px);
        }
        th.next{
            font-size: get-vw(25px);
            width: get-vw(30px);
            height: get-vw(25px);
        }
    }
    .datepicker th.datepicker-switch{
        height: get-vw(30px);
        font-size: get-vw(16px);
    }
}

@include media-breakpoint-down(md) {
    .birthday-box{
        margin-bottom: 30px;
        background-image: url("/img/birthday_background_mobile.jpg");
       
        .btn-primary{
            margin: 15px 0 ;
        }

        h2{
            text-align: center;
            padding-top: 25px;
           
        }
    }
    
}

@include media-breakpoint-down(lg) {
  .birthday-container .birthday-box .form-horizontal > div {
    padding-top: 30px;
  }
}
