$carrier-selector: "carrier";

/* body#checkout {
  color: $gray-darker;

  #header .header-nav {
    max-height: none;
    padding: 0.9375rem 0;
    margin-bottom: 0;
    border: none;

    .logo {
      width: auto;
      max-height: 4.375rem;
    }

    #_desktop_contact_link {
      #contact-link {
        margin-top: 0;
      }
    }
  }

  .cart-grid-right {
    .card {
      border: 0;
      box-shadow: none;

      .cart-total {
        background-color: inherit;
      }

      .cart-summary-subtotals-container {
        padding: 1rem 0;
        border-top: 1px solid $border-gray;
        border-bottom: 1px solid $border-gray;

        .cart-summary-line {
          margin-bottom: 0;

          .label {
            font-size: 1rem;
            font-weight: 500;
          }

          .value {
            font-size: 1rem;
            font-weight: 600;
          }

          &:not(:last-child) {
            margin-bottom: 0.825rem;
          }
        }
      }
    }
  }

  .custom-checkbox {
    display: flex;

    > label {
      padding-left: 1.5rem;
    }

    span {
      position: absolute;
      top: 0.025rem;
      left: 0;
      flex: 0 0 15px;
      margin-top: 0.1rem;
    }
  }

  // a:hover {
  //   color: #1a8196;
  // }

  section#content {
    margin-bottom: 1.563rem;
  }

  .container {
    min-height: 100%;
  }

  section.checkout-step {
    padding: 0.625rem;
    background-color: $white;

    &:last-child {
      border: 0;
    }

    .step-title {
      padding-bottom: 1.25rem;
      margin-bottom: 0;
      font-size: 1.5rem;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 1px solid $border-gray;
    }

    &.-current + .checkout-step {
      .step-title {
        padding-top: 1.25rem;
        border-top: 1px solid $border-gray;
      }
    }

    &.-complete:first-of-type {
      .step-title {
        padding-top: 0.625rem;
      }
    }

    .content {
      padding: 0 2.313rem;
      padding-top: 1rem;
    }

    .step-edit {
      font-weight: 500;
      text-transform: lowercase;

      .edit {
        font-size: $base-font-size;
      }
    }

    .not-allowed {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .content,
    .done,
    .step-edit {
      display: none;
    }

    &.-unreachable {
      .step-title {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.-current {
      .step-title {
        cursor: default;
      }

      .content {
        display: block;
      }
    }

    &.-current.-reachable.-complete {
      .done,
      .step-edit {
        display: none;
      }

      .step-number {
        display: inline-flex;
      }

      .content {
        display: block;
      }
    }

    &.-reachable.-current {
      .step-number {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        margin-right: 0.65rem;
        font-size: 1rem;
        color: $white;
        vertical-align: middle;
        background-color: $current-step-color;
        border: none;
        border-radius: 50%;
      }
    }

    &.-reachable.-complete {
      h1 {
        .done {
          display: inline-block;
        }

        .step-number {
          display: none;
        }

        .step-edit {
          display: block;
          float: right;
          margin-right: 0.125rem;
          color: $gray;
          cursor: pointer;
        }
      }

      .content {
        display: none;
      }
    }

    small {
      color: $gray;
    }

    .default-input {
      min-width: 40%;

      &[name="address1"],
      &[name="address2"] {
        min-width: 60%;
      }
    }

    .radio-field {
      margin-top: 1.875rem;

      label {
        display: inline;
      }
    }

    .checkbox-field div {
      margin-top: 3.75rem;
    }

    .checkbox-field + .checkbox-field div {
      margin-top: 0;
    }

    .select-field div {
      padding: 0.625rem 3.125rem;
      background: $gray-lighter;
    }

    .form-footer {
      text-align: center;
    }

    #conditions-to-approve {
      padding-top: 1rem;
    }

    .payment-options {
      label {
        display: table-cell;
      }

      .custom-radio {
        margin-right: 0.5rem;
      }

      .payment-option {
        margin-bottom: 0.5rem;
      }
    }

    .step-number {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 33px;
      margin-right: 1rem;
      margin-left: 0.5rem;
      font-size: 1rem;
      vertical-align: middle;
      border: 1px solid $gray-darker;
      border-radius: 50%;
    }

    .address-selector {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }

    .address-item {
      flex: 0 0 49%;
      margin-bottom: $small-space;
      background: $gray-lighter;
      border: 3px solid transparent;

      > header {
        min-height: 11.7rem;
      }

      &.selected {
        background: $white;
        border: $brand-primary 3px solid;
      }
    }

    .address-alias {
      display: inline-block;
      margin-bottom: 0.625rem;
      font-weight: 600;
    }

    .address {
      margin-left: 1.563rem;
      font-weight: 500;
    }

    .radio-block {
      padding: 0.9375rem;
      text-align: left;
      cursor: pointer;
    }

    // .custom-radio {
    //   input[type="radio"] {
    //     width: 1.25rem;
    //     height: 1.25rem;
    //   }
    // }

    .delete-address,
    .edit-address {
      display: inline-block;
      margin: 0 0.3125rem;
      color: $gray;

      .delete,
      .edit {
        font-size: $base-font-size;
      }
    }

    hr {
      margin: 0;
    }

    .address-footer {
      padding: 0.625rem;
      text-align: center;
    }

    #delivery-addresses,
    #invoice-addresses {
      margin-top: 1.25rem;
    }

    .add-address {
      margin-top: 1.25rem;

      a {
        color: $gray-darker;

        i {
          font-size: $font-size-lg;
        }
      }
    }

    .delivery-options {
      > .row {
        border-right: 0.1rem solid darken($gray-lighter, 20%);
      }

      .delivery-option {
        min-height: 80px;
        padding: 0.9375rem 0;
        margin-bottom: 0.9375rem;
        background: $gray-lighter;

        &,
        label > .row,
        .carrier {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        }

        label {
          margin-bottom: 0;
          text-align: inherit;

          .#{$carrier-selector} {
            margin-left: 0;

            &:not(.#{$carrier-selector}-hasLogo) {
              > .col-xs-12 {
                padding-left: calc(33.33333% + 15px);
              }
            }
          }
        }

        img {
          width: 3.125rem;
        }
      }
    }

    .carrier-delay,
    .carrier-name {
      display: inline-block;
      margin-bottom: 0;
      text-align: left;
      word-break: break-word;
    }

    #customer-form,
    #delivery-address,
    #invoice-address,
    #login-form {
      margin-top: 1.563rem;
      margin-left: 0.3125rem;

      .form-control-label {
        text-align: left;
      }

      .radio-inline {
        padding: 0;
      }
    }

    .sign-in {
      font-size: $font-size-sm;
    }

    .forgot-password {
      margin-left: 14.38rem;
    }
  }

  .additional-information {
    margin-top: 1.25rem;
    margin-left: 2.875rem;
    font-size: $font-size-sm;
  }

  .condition-label {
    // padding-left: 1.625rem;
    // margin-top: 0.625rem;

    label {
      text-align: inherit;
    }
  }

  .cancel-address {
    display: block;
    margin: 0.625rem;
    color: $gray;
    text-decoration: underline;
  }

  .modal-content {
    padding: $medium-space;
    background-color: $gray-light;
  }

  #cart-summary-product-list {
    font-size: $font-size-sm;

    img {
      width: 3.125rem;
      border: $gray-light 1px solid;
    }

    .media-body {
      vertical-align: middle;
    }
  }

  #order-summary-content {
    padding-top: 0.9375rem;

    h4.h4 {
      margin-top: 0.625rem;
      margin-bottom: 1.25rem;
      color: $gray-darker;
    }

    h4.black {
      color: #000;
    }

    h4.addresshead {
      margin-top: 0.1875rem;
    }

    .noshadow {
      box-shadow: none;
    }

    #order-items {
      border-right: 0;

      h3.h3 {
        margin-top: 1.25rem;
        color: $gray-darker;
      }

      table {
        tr {
          &:first-child {
            td {
              border-top: 0;
            }
          }
        }
      }
    }

    .order-confirmation-table {
      padding: 1rem;
      margin-bottom: 2rem;
      background-color: $white;
      border: 3px solid #e5e5e5;
      border-radius: 0;
    }

    .summary-selected-carrier {
      padding: 1rem;
      margin-bottom: 0.75rem;
      background-color: $white;
      border: 1px solid #e5e5e5;
      border-radius: 0;

      .logo-container {
        text-align: center;

        img {
          width: 3.125rem;
        }
      }
    }

    .step-edit {
      display: inline;
      color: $gray;
    }

    .step-edit:hover {
      cursor: pointer;
    }

    a {
      .step-edit {
        color: $gray;
      }
    }
  }

  #gift_message,
  #delivery {
    max-width: 100%;
    border-color: $gray-darker;

    textarea {
      max-width: 100%;
      // margin-bottom: 10px;
    }
  }

  // #footer {
  //   padding: 0.9375rem;
  //   color: $gray;
  //   background: $white;
  // }

  #delivery {
    > label {
      text-align: left;
    }
  }

  #hook-display-after-carrier {
    margin-top: 20px;

    > [class^="col"] {
      display: inline-block;
      float: inherit;

      &.links {
        .row > [class^="col"] {
          width: auto;
        }
      }
    }
  }
} */

/**** ORDER CONFIRMATION *****/
#order-details {
  padding-left: 1.3rem;
  margin-top: 1.25rem;

  > .card-title {
    margin-bottom: $large-space;
  }

  ul {
    margin-bottom: $medium-space;

    li {
      margin-bottom: $small-space;
    }
  }
}
hr{
  border-color: #F3F3F3;
}
#order-items {
  // border-right: $gray-light 1px solid;

  .h3 {
    font-size: 1rem;

    &._desktop-title {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  hr {
    border-top-color: $gray-darker;
  }

  table {
    width: 100%;

    tr {
      height: $large-space;

      td {
        padding: 0.5rem;
        font-size: 0.875rem;

        &:last-child {
          text-align: right;
        }
      }

      &.total-value {
        // background-color: $gray-light;
      }

      &.taxes {
        td {
          text-align: left;

          .value {
            padding-top: 0;
          }
        }
      }
    }
  }

  .order-line {
    margin-top: 1rem;
  }

  .image {
    img {
      width: 100%;
      margin-bottom: 1rem;
      border: 1px solid gray-lighter;
    }
  }

  .details {
    margin-bottom: 1rem;

    .customizations {
      margin-top: 0.625rem;
    }
  }

  // .qty {
  //   margin-bottom: 1rem;
  // }
}

#order-confirmation {
  #registration-form {
    width: 50%;
    margin: 0 auto 1rem;
  }
}

@include media-breakpoint-down(md) {
  .done {
    padding: 0;
    margin-right: 0.625rem;
  }

  /* body#checkout section.checkout-step .address-item {
    flex-grow: 1;
  }

  body#checkout section.checkout-step .delivery-option-2 {
    flex-direction: column;
  } */

  .delivery-option {
    display: flex;
    margin: auto;

    .custom-radio {
      flex: 0 0 auto;
    }
  }

  .condition-label {
    label[for="conditions_to_approve[terms-and-conditions]"] {
      text-align: left;
    }
  }

  #order-confirmation {
    #registration-form {
      width: 100%;
    }
  }

  /* body#checkout section.checkout-step .delivery-options .delivery-option {
    .carrier {
      > .col-xs-12 {
        padding-left: 0;
      }
    }
  } */
}

@include media-breakpoint-down(sm) {
  /* body#checkout section.checkout-step.-reachable.-complete h1 .step-edit {
    float: none;
    margin-top: 0.25rem;
    margin-left: $medium-space;
  }

  body#checkout {
    #header .header-nav {
      max-height: none;
      padding: 0;
    }

    section.checkout-step {
      .content {
        padding: 0.9375rem;
      }
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
  } */

  #order-items {
    margin-bottom: 2.5rem;
    border-right: 0;

    .card-title {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-light;
    }

    hr {
      border-top-color: $gray-light;
    }
  }

  .bold {
    font-weight: 600;
  }

  #order-details {
    padding-left: 0.9375rem;

    .card-title {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-light;
    }
  }

  /* body#checkout section.checkout-step .delivery-options .delivery-option {
    label .#{$carrier-selector} {
      &:not(.#{$carrier-selector}-hasLogo) {
        .col-xs-12 {
          padding-left: 0;
        }
      }
    }
  } */
}

@include media-breakpoint-down(xs) {
  /* body#checkout {
    section.checkout-step {
      .content {
        padding: 0.9375rem 0;
      }
    }
  } */

  #payment-confirmation {
    button {
      font-size: 0.875rem;

      &.btn {
        white-space: normal;
      }
    }
  }
}

.js-payment-binary {
  display: none;

  .accept-cgv {
    display: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      content: "";
    }

    .accept-cgv {
      display: block;
    }
  }
}
