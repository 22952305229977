/*** IBIF ***/
// List of all hover effects used in theme
// All the rules use css variables, that can be overwritten for easy speed/color/size manipulation
// Classes naming convention is as follows:
// "ibe" + "_element" + "-modifier" (ibe stands for: "ib" - ibif custom class, "e" - effect)

/*******************/
// VARIABLES
/*******************/

// none

/*******************/
//RULES
/*******************/

// text underline effects (3 versions
%text-underline {
    --bg-h: 1px;
    --color: #{$gray-darker};

    background: linear-gradient(0deg, var(--color), var(--color)) no-repeat;
    background-size: 0 var(--bg-h);
    transition: background-size $tspeed-300;
    // padding-bottom: 2px;
    // --bg-h: 2px;
}
.ibe_text-underline {
    @extend %text-underline;
    background-position: left bottom;
    &:hover, &:focus, .ibe_text-trigger:hover & {
        background-size: 100% var(--bg-h);
    }
}
.ibe_text-underline_short {
    @extend %text-underline;
    background-position: left bottom;
    &:hover, &:focus, .ibe_text-trigger:hover & {
        background-size: get-vw(40) var(--bg-h);
    }
}
.ibe_text-underline_passthrough {
    @extend %text-underline;
    background-position: right bottom;
    &:hover, &:focus, .ibe_text-trigger:hover & {
        background-size: 100% var(--bg-h);
        background-position-x: left;
    }
}
// .ibe_block-frame {
//     border: 1px solid $black;
//     overflow: hidden;
//     > * {
//         position: relative;
//     }
//     &:before, &:after {
//         content: "";
//         display: block;
//         position: absolute;
//         background: $white;
//         top:  50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         animation-iteration-count: 1;
//         transform-origin: 50% 50%;
//         opacity: 1;
//         z-index: -1;
//     }
//     &:before {
//         width: 120%;
//         height: 100%;
//         transition: height 1s ease, opacity .8s ease;
//     }
//     &:after {
//         height: 120%;
//         width: 100%;
//         transition: width 1s ease, opacity .8s ease;
//     }
//     &:hover, &:focus{
//         &:before {
//             transition: height .2s ease , opacity .3s ease;
//             height: 85%;
//             opacity: .7;
//         }
//         &:after {
//             transition: width .2s ease,  opacity .3s ease;
//             width: 85%;
//             opacity: .8;
//         }
//     }
// }

// block bg slide effect
.ibe_block-slide {
    --bg: #{$black};
    --color: #{$primary-color};
    --speed: #{$tspeed-300};
    --height: -50px;   // negative value goes from bot to top, positive value from top to bot
    box-shadow: inset 0 0 0 0 var(--bg);
    transition: color var(--speed) ease-in-out, box-shadow var(--speed) ease-in-out;
    &:hover, .ibe_block-trigger:hover & {
        box-shadow: inset 0 var(--height) 0 0 var(--bg);   
        color: var(--color);
    }
}

// block border effect (requires position-relative)
.ibe_block-sparkle {
    --padding: 0;
    --color: #{$black};
    --width: 0.2em;
    --speed: #{$tspeed-500};
    &::before,
    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        pointer-events: none;
        top: var(--padding);
        left: var(--padding);
        right: var(--padding);
        bottom: var(--padding);
        // height: 100%;
        // width: 100%;
        transform-origin: center;
        transition: transform var(--speed);
    }
    &::before {
        border-top: var(--width) solid var(--color);
        border-bottom: var(--width) solid var(--color);
        transform: scale3d(0,1,1);
    }
    &::after {
        border-left: var(--width) solid var(--color);
        border-right: var(--width) solid var(--color);
        transform: scale3d(1,0,1);
    }
    &:hover, .ibe_block-trigger:hover & {
        &::before,
        &::after {
            transform: scale3d(1,1,1);
        }
    }
}

// block zoom effect
.ibe_block-grow {
    --scale: 1.5;
    --speed: #{$tspeed-300};
    --ease: ease-in-out;

    transition: all var(--speed) var(--ease);
    &:hover, .ibe_block-trigger:hover & {
        transform: scale(var(--scale));
    }
}

// block shine effect (requires overflow-hidden and position-relative)
.ibe_block-shine {
    --speed: #{$tspeed-500};
    --ease: ease;
    --distance: 115%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -75%;
        z-index: 2;
        display: block;
        width: 50%;
        height: 100%;
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
        -webkit-transform: skewX(-25deg);
        transform: skewX(-25deg);
        transition: left var(--speed) var(--ease);
        pointer-events: none;
    }
    &:hover, .ibe_block-trigger:hover & {
        &::before {
            left: var(--distance);
        }
    }
}

// block background stretch from center effect (requires overflow-hidden and position-relative)
.ibe_block-border {
    --color: #{$black};
    --width: 2px;
    --speed: #{$tspeed-300};
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: var(--width) solid var(--color);
        z-index: 1;
        border-radius: inherit;
        opacity: 0;
        transform: scale3d(.6,.6,1);
        transition: transform var(--speed), opacity var(--speed);
        transition-timing-function: cubic-bezier(.75,0,.125,1);
    }
    &:hover, &:focus, .ibe_block-trigger:hover & {
        &::before {
            transform: scale3d(1,1,1);
            opacity: 1;
        }
    }
}