@import "../_prestashop/components/footer.scss";

.footer-container {

  a.ibe_text-underline {
    --color: #{$primary-color};
  }
  .h3,
  .h4 {
    color:$primary-color;
    // font-size: $font-size-sm;
    // margin-bottom: 2rem;
    font-size: 14px;
    font-weight: 500;
  }
  li a {
    color: inherit;
    font-weight: 400;
  }
  .footer-email{
    font-weight: 400;
  }
}
#footer {
  padding-top: 0; //if disabled newsletter footer block, set to 2.5rem
  background-color: #F3F3F3;
  #_desktop_language_selector_footer,
  #_desktop_currency_selector_footer,
  #_mobile_language_selector_footer,
  #_mobile_currency_selector_footer {
    // margin: 30px 0 0;
    // border-bottom: 1px $block-border-color solid;
    .dropdown {
      width: 100%;
      button {
        display: none;
      }
      .dropdown-menu {
        display: flex;
        position: relative;
        z-index: 100;
        // gap: 20px;
        border: 0;
        background-color: unset;
        li {
          padding: 0 10px 0 0;
          &.current {
            a {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  // #_desktop_language_selector {
  //   border: 0;
  // }
  #_desktop_currency_selector_footer,
  #_mobile_currency_selector_footer {
    .currency-selector {
      .dropdown-menu {
        flex-direction: row-reverse;
        justify-content: start;
      }
    }
  }
}
.footer-copyright {
  // color: $primary-color;
  text-transform: uppercase;
  // margin: get-vw(50) 0;
  // span {
    // margin-left: get-vw(20);
    // font-size: 12px;
    // &.icon {
    //   margin-right: get-vw(10);
    // }
  // }
  a {
    color: $primary-color;
    @extend .ib-transition-all;
    &:hover {
      color: $black;
    }
  }
  .fibicon-more {
    font-size: 20px;
  }
  .fibicon-paint-brush {
    font-size: 15px;
  }
  // .site-map {

  // }
  // .made-by {

  // }
  .tm-dodo2 {
    margin: auto;
  }
}
#_desktop_logo_footer {
  margin-right: 6rem;
}
.ib-scroll-top {
  right: 20px;
  bottom: 100px;
  width: 34px;
  line-height: 34px;
  z-index: 30;
  &.aos-init {
    pointer-events: none;
  }
  &.aos-animate {
    pointer-events: all;
  }
}
#header 
.block-social 
  {

    li a{
    color:$primary-color;
  }
}
.block-social {
  li {
    
    a {
      color:$primary-color;
      font-size: 30px;
      &:hover {
        i{
          color: $black;
        }
        
      }
    }
    &.facebook {
      order: 1;
    }
    &.instagram {
      order: 2;
    }
    &.pinterest {
      order: 3;
      .fibicon-pinterest {
        background: url("../img/pinterest.svg") no-repeat left center;
        background-size: contain;
        width: 100%;
        height: 30px;
        display: inline-block;
      }
    }
    &.linkedin {
      order: 4;
      .fibicon-linkedin {
        background: url("../img/linkedin.svg") no-repeat left center;
        background-position: center center;
        background-size: contain;
        width: 100%;
        height: 30px;
        display: inline-block;
      }
    }
    &.tiktok{
      order:5;
    }
    // &.youtube {
    //   order: 3;
    // }
    // &.twitter {
    //   order: 4;
    // }
  }
}
#contact-infos {
  .icon {
    width: 2rem;
    /* CLASSES: position-absolute ib-text-brown start-0 top-0 text-center ml-n5 */
    position: absolute; 
    color: $primary-color;
    top: 0;
    left: 0;
    text-align: center;
    margin-left: -3.4rem;
  }
}
.block-contact {
  a {
    // color: $primary-color;
  }
  p {
    font-size: inherit;
  }
}
.links {
  .collapse.show {
    display: block;
  }
}
footer hr.ib-index {
  border-color: $block-border-color;
  margin: 0 -20px -1px;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .block-social{
    ul{
      margin:0 10px 0 0;
      
      li{
        padding:0 5px;
      }
    }
  }
  .made-by{
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  #footer .block-social ul{
    margin-top: 15px;
    justify-content: center;
  }
  .links {
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
  }
  // .links.col-lg::after {
  //   content: '';
  //   display: block;
  //   border-bottom: 1px solid #e7e7e7;
  //   margin: 0 -20px;
  // }
  .footer-copyright {
    i {
      width: 20px;
      height: 20px;
    }
  }
}
.footer-phone{
  font-size: 25px;
  display: block;
}
@include media-breakpoint-up(lg) {
  .footer-phone{
    font-size: get-vw(30);
    line-height: get-vw(24);
    padding-bottom: get-vw(34);
  }
  .footer-desktop{
    margin-left:get-vw(65);
  }
  #contact-infos .icon{
    font-size: get-vw(50);
  }
  .ib-order-lg-default {
    order: initial;
  }
  .footer-container {
    // padding-top: $extra-large-space;
    .h3,
    .h4 {
      font-size: get-vw($font-size-sm);
      margin-bottom: get-vw(2rem);
      font-weight: 600;
    }
  }
  #footer {
    #_desktop_currency_selector_footer {
      position: absolute;
      right: 0;
      top: get-vw(100);
      border-bottom: 1px $block-border-color solid;      
    }
    #_desktop_language_selector_footer {
      position: absolute;
      right: 0;
      top: get-vw(150);
    }
    #_desktop_currency_selector_footer,
    #_desktop_language_selector_footer {
      .dropdown {
        width: get-vw(300);
        .dropdown-menu {
          li {
            padding: 0 get-vw(10) 0 get-vw(15);
          }
        }
      }
    }
  }
  .footer-copyright {
    padding:get-vw(80) 0 get-vw(38) 0;
    .fibicon-more {
      font-size: get-vw(20);
    }
    .fibicon-paint-brush {
      font-size: get-vw(15);
    }
  }
  .footer-container{
    li{
      a{
        font-size: get-vw(16.5);
      }
    }
  }
  .footer-container .block-social {
    padding-top: 0;
    margin-right: get-vw(75);
    .d-flex{
      justify-content: flex-end;

    }
    li {
      padding:0 get-vw(10);
      a {
        font-size: get-vw(40);
      }  

    }
  }
    .block-social {

    
   

    li {
      padding:0 get-vw(8);
      a {
        font-size: get-vw(35);
      }      
      &.pinterest {
        .fibicon-pinterest {
          height: get-vw(30px);
        }
      }  
      &.linkedin {
        .fibicon-linkedin {
          height: get-vw(30px);
        }
      }  
    }
  }
  .ib-scroll-top {
    right: get-vw(15);
    bottom: get-vw(180);
    width: get-vw(40);
    line-height: get-vw(40);
  }
  .links-2{
    margin-left: get-vw(95);
    margin-right: get-vw(50);
  }
}