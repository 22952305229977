.sitemap-title {
  text-transform: capitalize;
}

.sitemap {
  margin-top: 0.9375rem;

  h2 {
    // width: 100%;
    // height: 35px;
    margin-left: -15px;
    // font-size: 1.1rem;
    // font-weight: 600;
    color: $gray-darker;
    // text-transform: uppercase;
    // border-bottom: 1px solid $gray;
  }

  ul {
    // margin-top: 20px;
    margin-left: -15px;

    &.nested {
      margin-left: 20px;
    }

    li {
      // margin-bottom: 1rem;
      // font-size: 0.9rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .sitemap {
    margin-top: 0;
  }
}
