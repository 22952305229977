@import "../_prestashop/components/forgotten-password.scss";

#password {
  #content {
    max-width: 1600px;
    form {
      // border: 1px $block-border-color solid;
    }
  }
  #back-to-login {
    max-width: 280px;
  }
  .center-email-fields {
    align-items: center;
    flex-direction: column;
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
  #password {
    #back-to-login {
      max-width: get-vw(280);
    }
  }
}