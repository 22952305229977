.modal-content {
    border: none;
    border-radius: 0;
}
.modal-backdrop {
    @extend .ib-overlay;
    &.show {
        opacity: 1;
    }
    &:has(+.modal.Logowanie){
        display: none;
    }
}
.modal-header {
    border-bottom: none;
}
// @mixin ibif-image-modal {
.ib-gallery-modal {
    .modal-dialog {
        max-width: 1920px;
    }
    .modal-body {
        img {
            max-width: 80%;
            height: auto;
        }
    }
    .close {
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        z-index: 1000;
        &:hover {
            color: $primary-color;
        }
    }
    .ib-arrow {
        color: $black;
        font-size: 2rem;
        &:hover {
          color: $primary-color;
        }  
    }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
    #blockcart-modal {
        .modal-content{
          h4{
            i{
              left:-3.2vw
            }
          }
        }
    }
    .modal-dialog {
        max-width: get-vw(600);
    }
    .modal-header .close {
        font-size: get-vw(1.5rem);
    }
    .ib-gallery-modal {
        .ib-arrow {
            font-size: get-vw(2rem);
        }
    }
    .customization-modal {
        .modal-dialog {
            max-width: get-vw(800);
        }
        .modal-body {
            padding: get-vw(3.125rem) get-vw(1.875rem);
        }
    }
}