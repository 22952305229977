.form-control {
    padding: 0 18px;
    // color: $gray-second;
    color: $black;
    background-color: $input-bg-color;
    border: 2px solid #e0e0e0;
    height: 40px;
    font-size: 14px;
    border-radius: 10px!important;
    font-weight: 300;
    &:focus {
        // color: $input-text-color;
        box-shadow: none;
        border-color: $primary-color;
        outline: none;
    }
    &:active {
        &::placeholder {
            color: $breadcrumb-color;
        }
    }
    &::placeholder {
        color: $breadcrumb-color;
        opacity: 1;
        font-weight: 300;
    }
    &-label.required {
        &::after {
            content: "*";
            color: #ff3f3f;
            font-size: 12px;
            position: absolute;
            right: 2px;
            // margin-right: -1rem;
            margin-right: -0.75rem;
            top: 35%;
        }
        &.ets_checkinput::after {
            top: 0;
        }
    }
}
label {
    padding-left: 18px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 2px;
    sup {
        top: -2px;
        color: $red;
    }
}
label,
.label {
    font-size: 0.875rem;
}
.input-group {
    &.focus {
        outline: none;
    }
}
.bootstrap-touchspin {
    flex-wrap: nowrap;
    .form-control {
        height: 34px;
        margin: 0 5px;
        width: 100%;
        text-align: center;
    }
    .btn {
        width: 34px;
        padding: 0;
        height: 100%;
        text-indent: 100%;
        overflow: hidden;
        border: 2px solid #e0e0e0;
        font-size: 1rem;
        border-radius: 10px;
        &:hover {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            text-indent: 0;
            line-height: 34px;
        }
    }
}
.custom-radio {
    width: 20px;
    height: 20px;
    input[type="radio"] {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        +span {
            @include ib-transition(bg);
            background-color: $white;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 3px;
            border-radius: 50%;
        }
        &:checked+span {
            background-color: $black;
        }
    }
}
// #form_ets_onepagecheckout i.ets_checkbox {
%custom-checkbox {
    @include ibif-icon();
    @include ib-transition;
    @extend .fibicon-checked;
    // width: 25px;
    // height: 25px;
    // line-height: 23px;
    width: 20px;
    height: 20px;
    line-height: 19px;
    border: 2px solid $block-border-color;
    background-color: $white;
    text-align: center;
    margin-right: 0.75rem;
    font-size: 14px;

    &::before {
        color: $white;
    }
}
%custom-checkbox-checked {
    background-color: $primary-color;
    border-color: $primary-color;
}
.custom-checkbox {
    position: relative;
    input {
        // display: none;
        position: absolute;
        opacity: 0;
    }
    i {
        @extend %custom-checkbox;
        display: inline-block;
        border-radius: 7px;
    }
    input:checked+span i {
        @extend %custom-checkbox-checked;
    }
    span {
        text-transform: none;
    }
    a {
        color: $black;
        // @extend .ibe_text-underline;
        text-decoration: underline;
        @extend .ib-transition-all;        
        &:hover {
            color: $primary-color;
            text-decoration: none;
        }
    }
}

label .color,
.variant-links .color,
.custom-checkbox input[type="checkbox"] + span.color {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  cursor: pointer;
  background-size: contain;
  border: 1px solid $radio-border-color;
  &.active,
  &:hover {
    border: 1px solid $gray-darker;
  }
}

/*********************/
/*        RWD        */
/*********************/
@include media-breakpoint-down(lg) {
    .show-password button {
        height: 40px;
    }
}

@include media-breakpoint-up(lg) {
    .form-control {
        padding: 0 get-vw(18);
        height: get-vw(50);
        // font-size: 1rem;
        font-size: get-vw(16);
    }
    .bootstrap-touchspin {
        .form-control {
            height: get-vw(34);
            // margin: 0 5px;
        }
        .btn {
            width: get-vw(34);
            font-size: get-vw(1rem);
            &::before {
                line-height: get-vw(34);
            }
        }
    }
    label {
        padding-left: get-vw(18);
    }
    label,
    .label,
    form .form-control-label {
        font-size: get-vw(0.875rem);
    }
    .form-control-label {
        padding-top: get-vw(0.625rem);
    }
    %custom-checkbox {
        width: get-vw(25);
        height: get-vw(25);
        line-height: get-vw(23);
        font-size: get-vw(14);
    }
    .custom-radio {
        width: get-vw(25);
        height: get-vw(25);
        input[type="radio"] {
            +span {
                margin: get-vw(4);
            }
        }
    }
    label .color,
    .variant-links .color,
    .custom-checkbox input[type="checkbox"] + span.color {
        width: get-vw(25);
        height: get-vw(25);
    }    
}