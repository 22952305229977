.alert {
    border: 1px $block-border-color solid;
    padding: 1rem;
    border-radius: 0;
    margin: 1rem 0;
    ul {
        margin: 0;
        position: relative;
        padding-left: 100px;
        li {
            margin: 15px 0;
            font-weight: 300;
        }
        &::before {
            @include ibif-icon();
            position: absolute;
            left: 0;
            font-size: 38px;
            font-weight: 500;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &.alert-success {
        background: transparent;
        ul {
            li {
                color: $green;
            }
            &::before {
                content: $fibicon-checked;
                color: $green;
            }
        }
    }
    &.alert-danger {
        background: transparent;
        ul {
            li {
                color: $red;
            }
            &::before {
                content: $fibicon-cancel;
                color: $red;
            }
        }
    }
    &.alert-info,
    &.alert-warning {
        background: transparent;
        color:$primary-color;
        ul {
            li {
                color: $primary-color;
            }
            &::before {
                content: $fibicon-info;
                color: $primary-color;
            }
        }
    }
}
.modal {
    .alert {
        padding: get-vw(200);
    }
}

.alert-mini {
    border: 1px $primary-color solid;
    padding: 1rem;
    border-radius: 0;
    margin: 0.5rem 0;
    background: #F4EEE8; // [Art] kolejny kolor
    ul {
        margin: 0;
        position: relative;
        padding-left: 40px;
        li {
            margin: 0;
            font-weight: 300;
            color: $black;
        }
        &::before {
            // @include ibif-icon();
            content: '';
            background: url('../img/info.svg') no-repeat center center;
            background-size: 25px 25px;
            width: 25px;
            height: 25px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    // &.alert-success {
    //     ul {
    //         li {
    //             color: $black;
    //         }
    //     }
    // }
    // &.alert-danger {
    //     ul {
    //         li {
    //             color: $black;
    //         }
    //     }
    // }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
    .alert {
        padding: 2rem;
        ul {
            padding-left: get-vw(100);
            li {
                &:first-child {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                &.ib-lh-125 {
                    line-height: 1.25 !important;
                }
            }
        }
    }
    .alert-mini {
        padding: get-vw(1rem);
        margin: get-vw(0.5rem) 0;
        ul {
            padding-left: get-vw(40);
            &::before {
                background-size: get-vw(25) get-vw(25);
                width: get-vw(25);
                height: get-vw(25);
            }
        }
    }
    
}