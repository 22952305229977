.ib-rodo {
    z-index: 1002;
    a {
        text-decoration: underline;
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     bottom: 100%;
    //     z-index: 1001;
    //     width: 100%;
    //     height: 1000px;
    //     background: rgba(0,0,0,0.5);
    // }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(sm) {
    .ib-rodo .btn {
        width: auto;
    }
}