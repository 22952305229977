@import "../_prestashop/partials/_mixins.scss";

@mixin btn-hover() { 
  &:hover, &:focus, &.focus {
    box-shadow: none;
    @content;
  }
}
// Call disabled before active
@mixin btn-disabled() {
  &.disabled, &:disabled {
    @content;
  }
}
@mixin btn-active() {
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active/* , .show > &.dropdown-toggle  */{
    &, &:focus {
      box-shadow: none;
      @content;
    }
  }
}

@mixin block-with-icon-and-header {
  position: relative;
  border: 1px $block-border-color solid;
  padding: 45px 24px 30px 35px;
  overflow: visible;
  > .icon {
    border: 1px $block-border-color solid;
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    font-size: 23px;
    font-weight: 600;
    top: -20px;
    left: 50%;
    background: $white;
    transform: translateX(-50%);
    width: auto;
    color: $block-icon-color;
  }
  .block-header {
    color: $black;
    font-weight: 600;
    font-size: 18px;
    @include media-breakpoint-up(xl) {
      font-size: get-vw(18);
    }
  }
  @include media-breakpoint-up(lg) {
    padding: get-vw(40);
  }
}

@mixin ibif-icon-span {
  border: 1px $block-border-color solid;
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  background: $white;
  width: auto;
  margin-right: 30px;
  vertical-align: middle;
}

@mixin ibif-blockquote {
  border: 1px $block-border-color solid;
  border-left: 2px $primary-color solid;
  font-size: inherit;
  margin: get-vw(75) 0;
  padding: get-vw(35) get-vw(50);
  h4 {
    font-size: get-vw(22);
    font-weight: 500;
    margin-bottom: get-vw(30);
  }
  ol,
  ul {
    padding-left: 0;
    list-style-position: inside;
    li {
      color: $primary-color;
      // font-size: 0.9375rem;
      a { 
        color: $primary-color;
        border-bottom: 1px $white solid;
        // @extend .ib-transition-all;
        &:hover {
          border-bottom: 1px $primary-color solid;
        }
      }
    }
  }
}

@mixin ib-transition($type: all, $transition: $tspeed-250) {
  $types: (
    "all": null,
    "bg": background-color,
    text: color,
    "color": color,
  );
  transition: map-get($types, $type) $transition ease-out !important;
}