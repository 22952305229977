@import "../_prestashop/components/wrapper.scss";

#wrapper {
  overflow: hidden;   // FOR AOS ANIMATIONS
  .page-header {
    h1 {
      font-weight: 900;
      // margin: get-vw(40);
      margin: 0;
      padding: 30px;
      font-size: 25px;
    }
  }
  .breadcrumb {
    position: relative;
    z-index: 1;
    margin: 0;
    li {
      color: $black;
      font-size: 14px;
      font-weight: 500;
      &::after {
        @include ibif-icon();
        content: $fibicon-arrow-small-right;
        color:$primary-color;
        vertical-align: middle;
        margin: 0 get-vw(10);
      }
      &:last-child {
        color: $black;
      }
      a {
        color: $black;
      }
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      width: 100%;
      // height: 1px;
      // border-bottom: 1px $block-border-color solid;
    }
    &::before {
      top: 0;
    }
    &::after {
      // bottom: -1rem;
      bottom: 0;
    }
  }
  div ~ .ib-border-correction {
    border-bottom: 1px solid $white;
    z-index: 2;
    position: relative;
    margin: 0 -5rem -1px;
  }
  .grecaptcha-badge {
    z-index: 1;
  }
}



/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-up(lg) {
  #wrapper {
    .page-header {
      h1 {
        padding: get-vw(43);
        font-size: get-vw(30);
      }
    }
    .breadcrumb {
      li {
        font-size: get-vw(14);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #wrapper {
    .breadcrumb {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}