$ibificon-font-family: "ibificon" !default;
$ibificon-font-path: "../fonts/ibificon" !default;

$fibicon-angle-small-up: "\e900";
$fibicon-arrow-down-sign-to-navigate: "\e901";
$fibicon-arrow-small-left: "\e902";
$fibicon-arrow-small-right: "\e903";
$fibicon-blind: "\e904";
$fibicon-briefcase: "\e905";
$fibicon-bubble-chat: "\e906";
$fibicon-calendar: "\e907";
$fibicon-cancel: "\e908";
$fibicon-check: "\e909";
$fibicon-checked: "\e90a";
$fibicon-cursor: "\e90b";
$fibicon-doc: "\e90c";
$fibicon-down-arrow: "\e90d";
$fibicon-email_1: "\e90e";
$fibicon-email_2: "\e90f";
$fibicon-eye: "\e910";
$fibicon-facebook: "\e911";
$fibicon-heart: "\e912";
$fibicon-heart-full: "\e913";
$fibicon-home: "\e914";
$fibicon-info: "\e915";
$fibicon-instagram: "\e916";
$fibicon-jpeg: "\e917";
$fibicon-key: "\e918";
$fibicon-left-arrow: "\e919";
$fibicon-load: "\e91a";
$fibicon-lock: "\e91b";
$fibicon-log-out: "\e91c";
$fibicon-loupe: "\e91d";
$fibicon-menu: "\e91e";
$fibicon-minus: "\e91f";
$fibicon-more: "\e920";
$fibicon-notification: "\e921";
$fibicon-paint-brush: "\e922";
$fibicon-pdf: "\e923";
$fibicon-placeholder: "\e924";
$fibicon-plus: "\e925";
$fibicon-png: "\e926";
$fibicon-previous: "\e927";
$fibicon-ribbon: "\e928";
$fibicon-right-arrow: "\e929";
$fibicon-settings: "\e92a";
$fibicon-shield: "\e92b";
$fibicon-shopping-bag: "\e92c";
$fibicon-shopping-cart: "\e92d";
$fibicon-smartphone: "\e92e";
$fibicon-star: "\e92f";
$fibicon-trash-can: "\e930";
$fibicon-truck: "\e931";
$fibicon-twitter: "\e932";
$fibicon-undo: "\e933";
$fibicon-user: "\e934";
$fibicon-user1: "\e935";
$fibicon-user1a: "\e936";
$fibicon-user1b: "\e937";
$fibicon-user2: "\e938";
$fibicon-user2a: "\e939";
$fibicon-user2b: "\e93a";
$fibicon-user3a: "\e93b";
$fibicon-user3b: "\e93c";
$fibicon-wall-clock: "\e93d";
$fibicon-xls: "\e93e";
$fibicon-youtube: "\e93f";
$fibicon-zip: "\e940";

