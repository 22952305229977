@import "../_prestashop/components/contact.scss";

#top-column {
  // padding: 0;
  .contact-rich {
    &.rows {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
      @include media-breakpoint-between(sm,lg) {
        grid-template-columns: repeat(2, 1fr);
      }    
      @include media-breakpoint-down(xs) {
        grid-template-columns: 1fr;
      }
    }
    &.cols {
      .block {

        background: transparent;
      }
    }
    .block {
      box-shadow: 0 0 19px 0px #e5dfdf;
      border-radius: 10px;
      padding:15px;
      .icon{
        font-size: 40px;
      }
      .block-social ul{
        justify-content: center;
      }
      .data {
        font-size: 18px;
        a {
          // color: $brown;
          display: inline-block;
          &:hover {
            color: $black;
          }
        }
        a[href^="mailto:"] {
          @include media-breakpoint-up(sm) {
            font-size: inherit;
          }
        }
        &.phone {
          a {
            color: $black;
          }
        }
      }
    }
    .gotomap {
      font-size: 12px;
    }
  }
}

.contact-form {
  padding: 25px;
  border: 1px $block-border-color solid;
  h3 {
    color:$primary-color;
    font-size: 20px;
    text-transform: none;
    font-weight: 600;
  }
  .form-fields-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }
  textarea {
    height: 150px;
    padding: 10px 18px;
  }
  #send-confirmation {
    top: -70px;
  }
}
#top-column .contact-rich.cols .block.contact-social .icons .icon:hover{
  color:$black;
}
#contact-map {
  margin: 0;
  h3 {
    font-size: 20px;
    text-transform: none;
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
  }
}
.details,.footer-phone{
  font-weight: 600;
}

/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .contact-form {
    .alert {
      border: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact-form {
    .custom-checkbox p{
      font-size: get-vw(15);
    }
  }
  .contact-form{
    padding: get-vw(43) get-vw(92) get-vw(43) get-vw(56);
    .form-fields-row{
      column-gap:get-vw(38);
      row-gap: 0;
    }
    .form-control-label {
      padding-top: get-vw(5);
    }
  }
  #top-column .contact-rich.cols {
    margin:0 get-vw(62) 0 get-vw(80);
    
    .block{
      padding: get-vw(34) get-vw(0) get-vw(38) get-vw(26);
      .block-social ul{
        justify-content: unset;
      }
      &.contact-social{
        padding-top: get-vw(90);
        .icons{
          
          padding-left: get-vw(70);
          padding-top: get-vw(5);
          li{
            padding:0 get-vw(5);
          }
          .icon{
            font-size: get-vw(52);
          }
          
        }
      }
      .contact-email{
        font-size: get-vw(19);
        font-weight: 400;

      }
      .footer-phone, .contact-email{
        padding-left: get-vw(12);
        padding-bottom: 0;
      }
    }
  }
  #top-column .contact-rich .block .icon{
    font-size: get-vw(50);
  }
  #top-column {
    // padding: 0;
    .contact-rich {
      gap: get-vw(40);
    }  
  }
  .contact-form {
    border: 0;
    h3 {
      font-size: get-vw(25);
    }
    textarea {
      height: get-vw(150);
      padding: get-vw(10) get-vw(18);
    }  
  }
  #contact-map {
    margin: get-vw(80) 0;
    h3 {
      font-size: 1.875rem;
      margin: get-vw(30) 0;
    }
  }  
}

@include media-breakpoint-up(xl) {
  #top-column {
    // padding: 0;
    .contact-rich {
      gap: get-vw(20);
      .block {
        .data {
          font-size: get-vw(18);
        }
      }
    }  
  }
}