#left-column .title i,
.category-sub-menu .collapse-icons i {
    font-size: 0.45rem;
    font-weight: bolder;
    color:$primary-color;
}
.ib-left-pane.block-categories{
    background-color: #f4f4f4;
    border:none;
}
.ib-left-pane {
    border:1px solid #e0e0e0;
    border-radius: 10px;
    padding: 0 1.25rem;
    margin-bottom: 2rem;
}
label.facet-label {
    text-transform: none;
    font-size: inherit;
    line-height: 100%;
    .color {
    }
}
#search_filters {
    ul {
        margin: 0;
    }
    .custom-checkbox,
    .custom-radio {
        top: 0 !important;
    }
    .custom-checkbox i {
        cursor: pointer;
    }
}
div#search_filters {
    .ui-slider-horizontal {
        height: 5px;
        background: $block-border-color;
        border-color: $block-border-color;
    }
    .ui-slider .ui-slider-handle {
        width: 15px;
        height: 15px;
        top: -6px;
        margin-left: -6px;
        border: 2px solid $primary-color;
        border-radius: 50%;
    }
    .ui-widget-header {
        background: $primary-color;
        top: -1px;
        bottom: -1px;
        height: auto;
    }
}
.facet + .facet::before {
    content: '';
    display: block;
    border-top: 1px solid $block-border-color;
}
.facet > ul::after {
    content: '';
    display: block;
    margin-bottom: 0.75rem;
}
.facet-label.active .checked-indicator i {
    @extend %custom-checkbox-checked;
}
.facet-label a {
    color: inherit;
}
.facet li {
    margin: 0.875rem 0;
}
.facet li:first-child {
    margin-top: 0.75rem;
}
.facet > .title {
    cursor: pointer;
    // &.collapsed {
    //     i {
    //         // transform: rotate(180deg);
    //         @extend .ib-flipY;
    //     }
    // }
}
.block-categories {
    li {
        position: relative;
    }
    li[data-depth="0"] {
        // margin: 0.9rem 0;
        > div > ul {
            // margin-top: 1.625rem;
            // margin-top: 0.75rem;
            margin-top: 0.5rem;
        }
    }
    li[data-depth="1"] {
        // ul {
            // margin-bottom: 0.9rem;
        // }
    }
    a {
        color: inherit;
        padding-bottom: 5px;
    }
    a:hover,
    .current > div >  a {
        color: $primary-color;
    }
    .current > div >  a {
        background-size: get-vw(40) var(--bg-h);
    }
}
.ib-nb {
    color: $breadcrumb-color;
}
.ib-show-facets {
    background: none;
    border: none;
}
.faceted-input {
    max-width: 250px;
    height: 35px;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
    // #search_filters_wrapper {
    .ib-mobile-listing {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: white;
        overflow: auto;
        margin: 0 !important;
        // @include ib-transition;
        &.active {
            left: 0%;
        }
        .menu-toggle-close {
            background: none;
            margin-right: 20px !important;
        }
    }
    .ib-left-column-actions {
        z-index: 999;
        // .ib-heading {
        // }
        .menu-toggle {
            width: 50%;
            border: none;
            &.active {
              width: 100%;
              i {
                // @extend .ib-flip-y;
                display: inline-block;
                transform: scaleY(-1);
              }
            }
        }
    }
    .ib-left-pane {
        border-top: none;
        margin-top: -1rem;
    }
    #search_filters {
        padding-bottom: 3.5rem;
    }
    .block-categories li {
        padding: 0.25rem 0;
        &[data-depth="0"] {
            padding: 0.5rem 0;
        }
    }
    .block-categories li > .collapse-icons {
        margin-top: 3px;
    }
    .block-categories li[data-depth="0"] > .collapse-icons {
        margin-top: 5px;
        border: none;
    }
    .block-categories .category-sub-menu ul li::before {
        content: '';
        position: absolute;
        left: -20px;
        width: 5px;
        height: 5px;
        background: $pagination-blog-border-color;
        top: 11px;
        border-radius: 50%;
    }
    .block-categories .current > div > a {
        background: none;
    }
}
@include media-breakpoint-up(lg) {
        
    .block-categories {
        li[data-depth="0"] {
            margin: get-vw(0.9rem) 0;
            > div > ul {
                margin-top: get-vw(1.625rem);
            }
        }
        li[data-depth="1"] {
            ul {
                margin-bottom: get-vw(0.9rem);
            }
        }
        a {
            padding-bottom: get-vw(5);
        }
    }
    .ib-left-pane {
        padding: 0 get-vw(1.25rem);
        margin-bottom: get-vw(2rem);
    }
    .facet li {
        margin: get-vw(0.875rem) 0;
    }
    #left-column {
        .title i {
            font-size: get-vw(0.625rem);
        }
    }
    div#search_filters {
        .ui-slider-horizontal {
            height: get-vw(5);
        }
        .ui-slider .ui-slider-handle {
            width: get-vw(15);
            height: get-vw(15);
            top: get-vw(-6);
            margin-left: get-vw(-6);
            border-width: get-vw(2);
        }
    }
}