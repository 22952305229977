@import "../_prestashop/components/checkout.scss";

#order-confirmation {
  #content-wrapper {
    &:not(.left-column) {
      max-width: 1600px;
      margin: auto;
    }
  }
  #order-items {
    h3 {
      background: $whiteSmoke;
      // padding: 1rem 0.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 600;
      font-size: 18px;
    }
    table {
      td {
        font-size: 18px;
      }
    }
    .card-title {
      text-transform: none;
    }
    .order-line {
      border-bottom: 1px $block-border-color solid;
    }  
  }
}