.amazzingblog {
  .blog-subcategories {
    .blog-subcategory {
      span {
        color: $brown;          
      }
    }
  }
  // padding-top: get-vw(40);
  &.blocks {
    // margin-bottom: get-vw(50);
    .list-view {
      .block-title {
        text-align: center;
        // padding: get-vw(40);
        margin: 0;
        h2 {
          font-weight: 500;
          color: $black;
          padding-bottom: 40px;
          font-size: 24px;
          // line-height: get-vw(36);
        }
      }
      .post-list {
        .post-item-wrapper {
          .post-item {
            padding: 0;
            border: 0;
            // margin-bottom: get-vw(50);
            .post-item-title {
              font-weight: 500;
              font-size: 14px;
              padding: 5px;
              a {
                color: $black;
                &:hover {
                  color: $brown;
                }
              }
            }
            &:hover {
              .post-item-title {
                a {
                  color: $brown;
                }
              }
            }
          }
        }
      }
    }
    .viewall {
      .btn {
        width: 100%;
        font-size: 14px;
        padding: 0;
        line-height: get-vw(50);
        .icon {
          position: absolute;
          top: 50%;
          right: 10px;
          font-size: 18px;
          transform: translateY(-50%);
        }
      }
    }
  }
  &.post-page {
    h2 {
      font-weight: 700;
      font-size: 18px;;
      // padding: 22px 0;
      border-top: 1px $block-border-color solid;
    }
    .post-info {
      // padding: 20px;
      border-top: 1px $block-border-color solid;
      border-bottom: 1px $block-border-color solid;
      color: $black;
      .icon {
        color: $brown;
        margin: 0 10px;
        font-size: 22px;
        vertical-align: bottom;
      }
      .post-tags {
        .icon {
          vertical-align: middle;
        }
        .post-tag {
          color: $brown;
          border: 1px $block-border-color solid;
          // padding: 4px 7px;
          &:hover {
            color: $black;
            background: $block-border-color;
          }
        }
      }
    }
    .post-content {
      blockquote {
        @include ibif-blockquote;
      }
    }
  }
}

.post-next-prev {
  // margin: get-vw(75) 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: get-vw(50);
}
.post-next-prev-block {
  border: 1px $block-border-color solid;
  a {
    display: flex;
    align-items: center;
    color: $black;
    &:hover {
      color: $brown;
    }
  }
  span {
    &.post_title_block {
      margin: 0 get-vw(40);
      > span {
        display: block;
      }
    }
    &.icon {
      font-size: 12px;
      font-weight: 700;
    }
    &.post_title {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    &.post_title_label {
      text-transform: uppercase;
      color: $brown;
      font-size: 12px;
    }
  }
}

.ab-pagination {
  .pages {
    span.pagination-item {
      font-weight: 500;
      background: $black;
      color: $white;
      border-color: $black;
    }
    .pagination-item {
      width: 30px;
      height: 30px;
      line-height: 28px;
      font-size: 14px;
      display: inline-block;
      border: 1px $pagination-blog-border-color solid;
      &.disabled {
        // color: $btn-disabled-color;
        background: $btn-disabled-background;
        opacity: .4;
        pointer-events: none;
      }
    }
    a.pagination-item {        
      border-color: $pagination-blog-border-color;
      color: $black;
      &:hover {
        background-color: $pagination-blog-border-color;
      }
      &.go-to-page {
        &.prev,
        &.next {
          text-transform: uppercase;
          width: auto;
        }
      }
    }
  }
}

// BLOG TILE
.amazzingblog {
  .post-item {
    padding: 0;
    border: 0;
    margin-bottom: 35px;
    .post-item-title {
      font-weight: 500;
      font-size: 16px;
      color: $black;
      // padding: 0 25px;
      a {
        color: $black;
        &:hover {
          color: $brown;
        }
      }
      &.overflow-ellipsis {
        white-space: unset;
      }
    }
    .post-item-date {
      // background: $brown;
      left: 0;
      &::before,
      &::after {
        display: none;
      }
    }
    .post-item-footer {
      // border: 0;
      font-size: 12px;
      .item-readmore {
        color: $black;
        span.icon {
          vertical-align: middle;
          margin-left: 10px;
        }
        &:hover {
          color: $brown;
        }
      }
    }   
    .ibe_block-grow {
      --scale: 1.1;
    }
    &:hover {
      .post-item-title {
        a {
          color: $brown;
        }
      }
    }
  }
}
.amazzingblog.displayHome {
  .swiper-horizontal {
    position: relative;
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(sm) {
  #module-amazzingblog-blog {
    #content-wrapper {
      padding: 0;
    }
    #wrapper {
      .container-fluid {
        &.padded {
          display: flex !important;
          flex-direction: column-reverse;
        }
      }
    }
    // .ib-col {
    //   width: 100%;
    // }
  }  
}
@include media-breakpoint-up(lg) {
  .post-next-prev-block {
    span {
      &.icon {
        font-size: get-vw(30);
        font-weight: 500;
      }
    }
  }
  // BLOG TILE
  .amazzingblog {
    .post-item {
      margin-bottom: get-vw(100);
      .post-item-title {
        font-size: get-vw(20);
        // padding: 0 get-vw(25);
      }
      .post-item-footer {
        font-size: get-vw(16);
        .item-readmore {
          span.icon {
            margin-left: get-vw(10);
          }
        }
      }
    }
    &.post-page {
      h2 {
        font-weight: 500;
        font-size: get-vw(26);
        // padding: get-vw(35) 0;
        border-top: 1px $block-border-color solid;
      }
      .post-info {
        // padding: get-vw(20);
        .icon {
          margin: 0 get-vw(10);
          font-size: get-vw(22);
        }
      }  
    }
    &.blocks {
      .list-view {
        .block-title {
          text-align: center;
          padding: get-vw(40);
          margin: 0;
          h2 {
            font-weight: 500;
            color: $brown;
            padding-bottom: 0;
            font-size: get-vw(20);
            line-height: get-vw(36);
          }
        }
        .post-list {
          .post-item-wrapper {
            .post-item {
              padding: 0;
              border: 0;
              margin-bottom: get-vw(50);
              .post-item-title {
                font-weight: 500;
                font-size: 14px;
                padding: 5px;
                a {
                  color: $black;
                  &:hover {
                    color: $brown;
                  }
                }
              }
              &:hover {
                .post-item-title {
                  a {
                    color: $brown;
                  }
                }
              }
            }
          }
        }  
      }
    }
  }
  .ab-pagination {
    .pages {
      .pagination-item {
        width: get-vw(50);
        height: get-vw(50);
        line-height: get-vw(48);
      }
    }
  }
 
}