@import "../_prestashop/components/categories.scss";

#category {
  #wrapper {
    > .container-fluid {
      display: flex;
      flex-wrap: wrap-reverse;
    }
  }
}
div.faceted-overlay {
  @extend .ib-overlay;
  z-index: 10000 !important;
  .overlay__content {
    // background: $white !important;
    // padding: 15px;    
    background: $white;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    // width: 50px !important;
    // height: 50px !important;
    // border-color: $black !important;
    // border-top-color: $white !important;
    @include ibif-icon();
    @extend .fibicon-load;
    width: auto;
    height: auto;
    font-size: 42px;
  }
}
h1{
  font-weight: 600;
}
.ib-category-h1 {
  // font-size: 1.875rem;
  font-size: 25px;

}
#js-active-search-filters.hide {
  display: none;
}
.active_filters {
  // background: $whiteSmoke;
  // border: 1px $block-border-color solid;
  // // margin-top: 1.25rem;
  .active-filter-title {
    text-transform: uppercase;
  }
  .filter-block {
    box-shadow: 0 0 19px 0px #e5dfdf;
    border-radius: 5px;
    padding: 7px 14px;
  }
}
#category-description {
  h2 {
    font-size: 20px;
  }
  h2, p {
    margin-bottom: 2rem;
  }
  p {
    font-size: 15px;
  }
}
.products-sort-order/* ,
.brands-sort,
.suppliers-sort */ {
  .select-title {
    font-size: 0.875rem;
    padding-left: 1.5rem;
    height: 35px;
    // padding-right: 0.5rem;
    min-width: 205px;
    color: $breadcrumb-color;
  }
  i {
    font-size: 0.625rem;
  }
}
.ib-prod-list-header {
  padding-right: 275px;
}
.page-not-found {
  #search_widget {
    width: 100%;
  }
  #search_widget_trigger {
    display: none !important;
  }
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(sm) {
  #category {
    #content-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@include media-breakpoint-up(lg) {
  .active_filters .filter-block{
    padding:get-vw(7) get-vw(14);
  }
  #category, #new-products
  {
    #wrapper > .container-fluid.category,#wrapper > .container-fluid.new-products{
      margin-top: 6.8vw;
      position: relative;
      padding: 0 7.5vw 0 8.2vw;
    }
  }
  #product, #new-products
  {
    #wrapper > .container-fluid.product{
      // margin-top: 6.8vw;
      position: relative;
      padding: 0 7.5vw 0 8.2vw;
    }
  }
  #product,#category, #new-products {
    #content-wrapper.js-content-wrapper{
      position: unset;
    }
  }
  
  
  #product .page-header,#js-product-list-header{
    position: absolute;
    top: -6.6vw;
    left: 0;
    right: 0;
  }
  div.faceted-overlay {
    .overlay__content {
      width: get-vw(80);
      height: get-vw(80);
    }
    .spinner {
      // width: 50px !important;
      // height: 50px !important;
      // border-color: $black !important;
      // border-top-color: $white !important;
      font-size: get-vw(42);
    }
  }
  .ib-category-h1 {
    font-size: get-vw(1.875rem);
  }
  .products-sort-order {
    .select-title {
      font-size: get-vw(0.875rem);
      padding-left: get-vw(1.5rem);
      height: get-vw(35);
      min-width: get-vw(205);
    }
  }
  #category-description {
    h2 {
      font-size: get-vw(1.5625rem);
    }
    h2, p {
      margin-bottom: get-vw(2rem);
    }
    p {
      font-size: get-vw(18);
    }  
  }
  .page-not-found {
    #search_widget {
      width: get-vw(800);
      // margin: auto;
    }
  }  
}