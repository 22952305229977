#product {
  #content {
    position: relative;
    // max-width: 452px;
    margin: 0 auto;
  }
}

.product-price {
  display: inline-block;
  // color: $primary-color;
}

#product-description-short {
  color: $gray-darker;

  ul,
  ol {
    padding-left: 0;
    list-style-position: inside;

    li {
      // font-size: 0.9375rem;
      color: $gray;
    }
  }

  ul {
    list-style-type: disc;
  }
}

.product-information {
  font-size: $font-size-lg;
  color: $gray-darker;

  .manufacturer-logo {
    max-height: 4rem;
    margin-bottom: 1rem;
    border: 2px solid $gray-light;
  }

  .product-description {
    img {
      max-width: 100%;
      height: auto;
    }

    ul,
    ol {
      padding-left: 0.75rem;
      list-style-position: inside;

      li {
        // font-size: 0.9375rem;
        color: $gray;
      }
    }

    ul {
      list-style-type: disc;
    }
  }
}

.input-color {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  opacity: 0;
}

.input-container {
  position: relative;
}

// .input-radio {
//   position: absolute;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   cursor: pointer;
//   opacity: 0;
// }

// .input-color,
// .input-radio {
//   &:checked + span,
//   &:hover + span {
//     border: 2px solid $gray-darker;
//   }
// }

// .radio-label {
//   display: inline-block;
//   padding: 0.125rem $small-space;
//   font-weight: 600;
//   background: $white;
//   border: 2px solid $white;
// }

.product-actions {
  .control-label {
    display: block;
    width: 100%;
    margin-bottom: 0.375rem;
  }

  .add-to-cart {
    // height: 2.75rem;
    // padding-top: 0.625rem;
    line-height: inherit;

    .material-icons {
      line-height: inherit;
    }
  }
}

.product-quantity {
  // display: flex;

  .qty,
  .add {
    display: inline-flex;
    float: left;
    margin-bottom: 0.5rem;
  }

  .qty {
    margin-right: 0.4rem;
  }

  #quantity_wanted {
    // width: 3rem;
    height: 2.75rem;
    padding: 0.175rem 0.5rem;
    color: $gray-darker;
    background-color: $white;
    margin:0 10px;
  }

  // .input-group-btn-vertical {
  //   width: auto;

  //   .btn {
  //     padding: 0.5rem 0.6875rem;

  //     i {
  //       top: 0.125rem;
  //       left: 0.1875rem;
  //       font-size: 1rem;
  //     }
  //   }
  // }

  // .btn-touchspin {
  //   height: 1.438rem;
  // }
}

.product-discounts {
  margin-bottom: 1.5rem;

  > .product-discounts-title {
    font-size: $font-size-sm;
    font-weight: 600;
  }

  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      text-align: center;
      background: $white;
      border: 0.3125rem $gray-light solid;
    }

    tbody tr {
      background: $gray-lighter;

      &:nth-of-type(even) {
        background: $white;
      }

      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: 0.3125rem $gray-light solid;
      }
    }
  }
}

.product-prices {
  margin-top: $medium-space;

  div {
    margin-bottom: 0.625rem;
  }

  // .tax-shipping-delivery-label {
  //   font-size: 0.8125rem;
  //   color: $gray;

  //   .delivery-information {
  //     padding: 0 0 0 2px;
  //     font-weight: 700;

  //     &::before {
  //       padding: 0 2px 0 0;
  //       content: "-";
  //     }
  //   }
  // }
}

// .product-discount {
  // color: $gray;

  // .regular-price {
    // margin-right: $small-space;
    // font-weight: 500;
    // text-decoration: line-through;
  // }
// }

// .has-discount {
//   &.product-price,
//   p {
//     color: $brand-secondary;
//   }

//   .discount {
//     display: inline-block;
//     padding: 0.3125rem $small-space;
//     margin-left: $small-space;
//     font-size: $font-size-base;
//     font-weight: 600;
//     color: $white;
//     text-transform: uppercase;
//     background: $brand-secondary;
//   }
// }

.product-unit-price {
  margin-bottom: 0;
  font-size: $font-size-xs;
  color: $primary-color;
}

.tabs {
  padding: $medium-space $large-space;
  margin-top: 2rem;
  background: $white;

  .tab-pane {
    padding-top: $medium-space;
  }

  .nav-tabs {
    border: none;
    border-bottom: $gray-light 2px solid;

    .nav-link {
      color: $gray;
      border: 0 solid transparent;

      &.active {
        color: $primary-color;
        border: none;
        border-bottom: $primary-color 3px solid;
      }

      &:hover {
        border: none;
        border-bottom: $primary-color 3px solid;
      }
    }

    .nav-item {
      float: left;
      margin-bottom: -0.125rem;
    }
  }
}

.product-cover {
  position: relative;
  margin-bottom: $medium-space;

  img {
    background: $white;
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    @include transition(opacity 0.7s ease-in-out);

    &:hover {
      opacity: 1;
    }

    .zoom-in {
      font-size: 3.125rem;
      color: $btn-primary-color;
    }
  }
}

// #product-modal {
//   .modal-content {
//     padding: 0;
//     background: transparent;
//     border: none;

//     .modal-body {
//       display: flex;
//       margin-left: -30%;

//       .product-cover-modal {
//         background: $white;
//       }

//       .image-caption {
//         width: 800px;
//         padding: 0.625rem 1.25rem;
//         background: $white;
//         border-top: $gray-light 1px solid;

//         p {
//           margin-bottom: 0;
//         }
//       }

//       .thumbnails {
//         position: relative;
//       }

//       .mask {
//         position: relative;
//         z-index: 1;
//         max-height: 49.38rem;
//         margin-top: 2.188rem;
//         overflow: hidden;

//         &.nomargin {
//           margin-top: 0;
//         }
//       }

//       .product-images {
//         margin-left: $extra-large-space;

//         img {
//           width: 9.25rem;
//           cursor: pointer;
//           background: $white;

//           &:hover {
//             border: $primary-color 3px solid;
//           }
//         }
//       }

//       .arrows {
//         position: absolute;
//         top: 0;
//         right: $large-space;
//         z-index: 0;
//         width: 100%;
//         height: 100%;
//         color: $white;
//         text-align: center;
//         cursor: pointer;

//         .arrow-up {
//           position: absolute;
//           top: -2rem;
//           opacity: 0.2;
//         }

//         .arrow-down {
//           position: absolute;
//           bottom: -2rem;
//         }

//         i {
//           display: inline;
//           font-size: 6.25rem;
//         }
//       }
//     }
//   }
// }

#blockcart-modal {
  color: $gray-darker;
  .modal-content{
    border-radius: 15px;
  }
  .modal-body{
    border-radius: 15px;
    padding-top:0!important;
  }
  .modal-header {
    border-radius: 15px;
    // background: $white;
    padding: 1rem;

    .close {
      // padding:0.75rem;
      border-radius: 15px;
      opacity: 1;

      // .material-icons {
      //   color: $gray-dark;
      // }
    }
  }

  .modal-body {
    padding: 3.125rem 1.875rem;
    background: $btn-warning-color;

    .divide-right span {
      display: inline-block;
      margin-bottom: 0.3125rem;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0 0.5rem;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    max-width: 1140px;
  }

  .product-image {
    display: block;
    width: 100%;
    // max-width: 9.375rem;
    // margin: 0 0 0 auto;

    // @include media-breakpoint-down(md) {
    //   max-width: 70%;
    //   margin: 0 auto 1rem;
    // }
  }

  // .modal-title {
  //   font-size: 1rem;
  //   font-weight: 400;
  //   color: #353943;

  //   i.material-icons {
  //     margin-right: $large-space;
  //     color: $brand-success;
  //   }
  // }

  // .product-name {
  //   font-size: 1.125rem;
  //   color: $primary-color;

  //   @include media-breakpoint-down(sm) {
  //     padding: 0 0.5rem;
  //   }
  // }

  .product-price {
    display: block;
    // color: $gray-dark;
    // @include media-breakpoint-down(sm) {
    //   padding: 0 0.5rem;
    // }
  }

  .cart-content {
    // @include media-breakpoint-up(md) {
    //   padding-left: $extra-large-space;
    // }

    // .btn {
    //   margin-bottom: $small-space;
    // }

    p {
      // display: flex;
      // justify-content: space-between;
      // padding: 0 0.5rem;
      // color: $gray-dark;

      &.product-total {
        padding: 0.5rem;
        background-color: $gray-light;
      }

      // &.cart-products-count {
      //   font-size: 1rem;
      //   font-weight: 600;
      //   color: $btn-tertiary-color;
      // }

      &.product-tax {
        display: inherit;
        font-size: 0.875rem;
      }

      .label,
      .value {
        font-weight: 700;
      }
    }

    .cart-content-btn {
      display: inline-flex;
      // justify-content: space-between;

      button {
        margin-right: 0.9rem;
      }

      .btn {
        white-space: inherit;
      }
    }
  }

  // .divide-right {
  //   border-right: 1px solid $gray-light-second;
  // }
}

// .product-images {
//   > li.thumb-container {
//     display: inline;

//     > .thumb {
//       margin-bottom: $small-space;
//       cursor: pointer;

//       .images-container & {
//         margin-right: 0.8125rem;
//       }

//       &.selected,
//       &:hover {
//         border: $primary-color 3px solid;
//       }
//     }
//   }
// }

#main {
  .images-container {
    .js-qv-mask {
      overflow: hidden;
      white-space: nowrap;

      &.scroll {
        width: calc(100% - 60px);
        margin: 0 auto;
      }
    }
  }
}

.scroll-box-arrows {
  display: none;

  &.scroll {
    display: block;
  }

  i {
    position: absolute;
    bottom: 1.625rem;
    height: 100px;
    line-height: 100px;
    cursor: pointer;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}

#product-availability {
  // display: inline-block;
  margin-top: 0.625rem;
  // font-weight: 700;

  // .material-icons {
  //   line-height: inherit;
  // }

  // .product-available {
  //   color: $brand-success;
  // }

  // .product-unavailable {
  //   color: $brand-warning;
  // }

  // .product-last-items {
  //   color: $brand-warning;
  // }
}

#product-details {
  // .label {
  //   font-size: $font-size-base;
  //   font-weight: 500;
  //   color: $gray-darker;
  // }
}

.product-features {
  margin-top: $medium-space;
  margin-left: 0.3125rem;

  > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    dd.value,
    dt.name {
      flex: 1 0 40%;
      min-height: 2.5rem;
      padding: $small-space;
      // margin-right: $small-space;
      margin-bottom: 0;
      font-weight: 500;
      text-transform: capitalize;
      word-break: normal;
      // background: $gray-light;

      // &:nth-of-type(even) {
      //   background: $gray-lighter;
      // }
      &.line-0{
        background-color: $white;
      }
    }
  }

}

.product-variants {
  > .product-variants-item {
    margin: $medium-space 0;

    select {
      width: auto;
      padding-right: 1.875rem;
      background-color: $white;
    }

    ul li {
      margin-right: $small-space;
    }

    .color {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.product-flags {
  position: absolute;
  // top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  pointer-events: none;

  li.product-flag {
    width: fit-content;
    // padding: 0.3125rem 0.4375rem;
    // margin-top: $small-space;
    // font-size: $base-font-size;
    // font-weight: 700;
    color: $white;
    text-transform: uppercase;
    pointer-events: auto;
    // background: $primary-color;

    &.online-only {
      position: absolute;
      top: 25rem;
      right: 0;
      z-index: 1;
      margin-top: 0;
      font-size: $font-size-xs;

      &::before {
        margin: 0.3125rem;
        font-family: "Material Icons", Arial, sans-serif;
        vertical-align: middle;
        content: "\E30A";
      }
    }

    // &.discount-percentage,
    // &.discount-amount,
    // &.discount {
    //   background-color: $brand-secondary;
    // }

    // &.on-sale {
    //   order: -1;
    //   width: 100%;
    //   // stylelint-disable-next-line
    //   margin-top: 0 !important;
    //   text-align: center;
    //   background: $brand-secondary;
    // }
  }
}

.product-customization {
  // margin: $extra-large-space 0;

  .product-customization-item {
    margin: $medium-space 0;
  }

  // .product-message {
  //   width: 100%;
  //   height: 3.125rem;
  //   padding: 0.625rem;
  //   resize: none;
  //   background: $gray-light;
  //   border: none;

  //   &:focus {
  //     background-color: $white;
  //     outline: 0.1875rem solid $primary-color;
  //   }
  // }

  .file-input {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 2.625rem;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
  }

  .customization-message {
    margin-top: 20px;
  }

  .custom-file {
    position: relative;
    display: block;
    width: 100%;
    height: 2.625rem;
    margin-top: $medium-space;
    line-height: 2.625rem;
    color: $gray;
    text-indent: 0.625rem;
    // background: $gray-light;

    button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
    }
  }

  small {
    color: $gray;
  }
}

.product-pack {
  margin-top: $extra-large-space;

  .pack-product-container {
    display: flex;
    justify-content: space-around;

    .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: $gray;
    }

    .pack-product-quantity {
      padding-left: $small-space;
      border-left: $gray-light 2px solid;
    }

    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 1.5rem;

  ul {
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: 0.25rem 0.25rem;
    background-size: 2rem 2rem;
    border-radius: 50%;
    @include transition(all 0.2s ease-in);

    a {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;

      &:hover {
        color: transparent;
      }
    }
  }
}

.products-selection {
  margin-bottom: $medium-space;

  .title {
    color: $gray;
  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
      height: auto;
    }
  }

  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;

    img.product-cover-modal {
      width: 100%;
    }

    .arrows {
      display: none;
    }
  }

  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }

  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: 1.875rem;
    }
  }

  .product-images > li.thumb-container > .thumb:not(.selected) {
    border: none;
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    // .modal-body {
    //   padding: 1rem;
    // }
  }
}
