#gdpr_consent {
  label {
    text-transform: none;
    font-size: 12px;
    a { 
      text-decoration: none;
      color: $primary-color;
      // @extend .ibe_text-underline;
    }
  }
}