@import "../_prestashop/components/header.scss";

#header {
  .header-top-right {
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    gap: get-vw(30);
    justify-content: space-around;
  }
  [id^="_desktop"] {
    i {
      display: inline-block;
      vertical-align: middle;
      // font-weight: 600;
      color: $primary-color;
      font-size: 25px;

      &.fibicon-shopping-cart {
        font-size: 30px;
      }
      &.fibicon-user1a,
      &.fibicon-user1b {
        font-size: 27px;
        max-width: 48px;
      }
      &.fibicon-heart {
        font-size: 30px;
      }
    }
    .ib-count {
      
      display: inline-block;
      vertical-align: middle;
      // font-size: 16px;
      font-size: 14px;
      // margin-left: 10px;
      margin-left: 5px;
      color: $white;
      background-color: $primary-color;
      border-radius:50%;
    }
    a:hover > i {
      color: $black;
    }
  }
  .dropdown-menu {
    z-index: 1001;
    min-width: unset;
  }
  .dropdown-menu a:hover {
    color: $primary-color;
  }
  .js-dropdown {
    // width: 50px;
    width: 100%;
    height: 50px;
    // border: 1px $block-border-color solid;
    .ib-flipY{
      color:$black;
    }
    button,
    ul {
      // width: 50px;
      width: 100%;
      transition : border .5s ease-out;
    }
    button {
      // padding: 0 5px;
      padding: 0 1.5rem;
      height: 50px;
      border-bottom: none;
    }
    ul {
      top: 0px !important;
      left: 0px !important;
      border-top: none;
      li {
        &.current {
          background: $whiteSmoke;
          a {
            color: $primary-color;
          }
        }
        a{
          text-align: center;
        }
        &:first-child {
          // margin-top: 5px;
        }
        // margin-bottom: 5px;
      }
    }
    &.show {
      @include box-shadow;
      button,
      ul {
        border-left-color: $block-border-color;
        border-right-color: $block-border-color;
        // width: 50px;
        width: 100%;
      }
      button {
        border-top-color: $block-border-color;
        border-bottom: none;
      }
      ul {
        @include box-shadow;
        border-bottom-color: $block-border-color;
        border-top: none;
        transform: none !important;
        li {
          border-bottom: 1px solid $block-border-color;
        }
      }  
    }
  }
}
.header-nav {
  display: none;
}
.cart-hover-content {
  border-radius: 10px;
  box-shadow: 0 0 19px 0px #e5dfdf;
  color: $black;
  right: 0;
  width: 320px;
  // z-index: 3;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  .product-quantity {
    color: $primary-color;   // [Art] kolejny szary, który nie jest nigdzie użyty
  }
  .btn {
    width: 200px;
    height: 40px;
  }
  // .cart-heading::after {
  //   content: '';
  //   display: block;
  //   border-bottom: 2px solid;
  //   width: 15px;
  //   margin-top: 1rem;
  // }
}
#header .cart-hover-content a.btn {
  font-weight: 500;
  color: $white;
  @include btn-hover {
    color: $black;
  }
}
#_desktop_cart .ib-blockcart-hover {
  &:hover {
    // GRACE AREA
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      right: 0;
      height: 85px;
      z-index: 3;
    }
    .cart-hover-content {
        visibility: visible;
        opacity: 1;
        @extend .mt-5;
    }
    a {
      z-index: 3;
    }
  }
}
// #_desktop_logo {
// #_desktop_logo_header {
//   max-width: 20%;
// }
#_desktop_currency_selector:empty {
    display: none;
}
.ib-trigger-menu {
  background: none;
  color: #555;
  // margin-right: -2px;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(sm) {
  #header .js-dropdown button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }
  #header #_desktop_logo_header img{
    width: 100px;
    max-width: 200px;
  }
  #header .ets_mm_megamenu.hook-default{
    padding:0;

  }
  #_desktop_user_info {
    margin-left: auto;
  }
}
@include media-breakpoint-down(md) {
  #header .header-top-right{
    .block-social{
      display: none;
    }
    #_mobile_currency_selector button{
      display: flex;
      align-items: center;
    }
  }
  #header #_desktop_cart {
    margin: 0 16px;
  }
  #header #_mobile_language_selector {
    max-width: 100px;
  }
  #_desktop_user_info {
    margin-right: 10px;
    display: none;
  }
  #_desktop_logo_header {
    max-width: 250px;
  }
  // #_desktop_wishlist,
  // #_desktop_x13favoriteproduct_link {
  //   position: absolute;
  //   bottom: 10px;
  //   right: 10px;
  //   width: 55px;
  // }
  // #header {
  //   padding-bottom: 60px;
  // }
  .ib-blockcart-hover {
    &::before,
    .cart-hover-content {
      display: none;
    }
  }
  // .header-top-right {
  //   // padding-right: 70px;
  //   padding-right: 63px;
  // }

  // DOCKABLE MENU
    .header-top {
      // min-height: 93px;
      &.dock-ready {
        .header-top-right {
          left: 0;
          right: 0;
          padding: 10px 20px;
          background: $white;
          box-shadow: 0 0 5px $pagination-blog-border-color;
          position: fixed !important;
          opacity: 0;
          top: -80px;
          transition: opacity $tspeed-500 ease, top $tspeed-500 ease;
        }
        #search_widget,
        // #_desktop_x13favoriteproduct_link,
        #_desktop_wishlist {
          display: none;
        }
        #_desktop_logo_header {
          max-width: 180px;
        }
      }
      &.docked {
        .header-top-right {
          top: 0;
          opacity: 1;
        }
      }
    }
    #mobileMenuObserverBottom {
      position: relative;
      top: 300px;
    }
    #mobileMenuObserverTop {
      position: relative;
      top: 150px;
    }
    #header {
      #_desktop_x13favoriteproduct_link {
        .ib-count {
          top: 7px;
          right: 5px;
        }
      }
      [id^="_desktop"] {
        .ib-count {
          font-size: 10px;
          color: $white;
          position: absolute;
          top: -12px;
          right: -12px;
          background: $primary-color;
          width: 17px;
          height: 17px;
          line-height: 17px;
          border-radius: 50%;
          text-align: center;
        }    
      }
    }    
}
@include media-breakpoint-up(lg) {
  
  #_desktop_user_info, #_desktop_cart{
    padding-top: 0.2vw;
  }
  
  .header-top{
    margin-bottom: 0.1vw;
  }
  .block-social{
    padding-top: 1vw;
    padding-left: 0;
    padding-right: 0.5vw;
  }
  #header {
    [id^="_desktop"] {

      .ib-count {
        position: absolute;
        width:get-vw(17);
        height:get-vw(17);
        top:get-vw(-15);
        right:get-vw(-25);
        display: flex ;
        justify-content: center;
        align-items: center;
        font-size: get-vw(12);
        margin-left: get-vw(10);
      }
      i {
        &.pigamicon-shopping-cart {
          font-size: get-vw(25);
        }
        &.pigamicon-user {
          font-size: get-vw(25);
         
        }
        &.fibicon-heart {
          font-size: get-vw(30);
        }
      }
    }
    .currency-selector.js-dropdown.show{
      ul{
        width: 100%;
      }
    }
    .js-dropdown {
      width: get-vw(120);
      height: get-vw(50);
      &.currency-selector{
        width: get-vw(80);
        button,ul{
          width: get-vw(80);
        }
      }
      .ib-flipY{
        font-size: get-vw(15);
      }
      button,
      ul {
        width: get-vw(120);
      }
      button {
        display: flex;
        align-items: center;
        padding: 0 get-vw(9);
        height: get-vw(50);
        img{
          width:get-vw(35);
        }
      }
      ul {
        li {
          padding: 0 get-vw(15);
          height: get-vw(50);
          margin-bottom: 0;
          &:first-child {
            // margin-top: get-vw(0);
          }
          // margin-bottom: get-vw(10);
        }
      }
      &.show {
        button,
        ul {
          width: get-vw(120);
        }
      }
    }  
  }
  .header-top.pt-lg-3 {
    padding-top: 1.1rem !important;
  }
  #_desktop_logo_header {
    margin-left: get-vw(80);
    min-width: get-vw(260);
    width: get-vw(260);
  }
  .cart-hover-content {
    width: get-vw(320);
    .btn {
      width: get-vw(220);
      height: get-vw(40);
    }
    .cart-heading::after {
      border-bottom-width: get-vw(2px);
      width: get-vw(15);
      margin-top: get-vw(1rem);
    }
  }
}