@import "../_prestashop/components/products.scss";

#product {
  
  #content {
    max-width: unset;
  }
  .gm_omniprice{
    width: 100%;
  }
}
#product .product-variants-item .div-gift{
  .attribute-31{
    display: none;
  }
  // text-align: center;
  i.icon{
      font-size: 40px;
      margin: 16px 0;
      display: block;
  }
  .ets_checkinput{
      padding-top: 15px;
  }
  .gift-price {
      font-size: 15px;
      margin:15px 0;
  }
  .custom-radio{
    width: auto;
    background-color: transparent;
    input[type="radio"] + span{
      background-color: transparent;
    }
  }
  label{
    max-width: unset;
    .radio-label{
      font-weight: 500;
    }

  }
  .gift-price{
    text-transform: lowercase;
  }
  
} 
.div-gift{
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 10px;
 
  
  align-items: center;
  
  i.icon{
      color:$primary-color;
  }
  .gift-price{

      display: flex;
      justify-content: center;
      align-items: center;

  }
}
.product-container {
  h1 {
    text-transform: none;
    font-weight: 500;
    font-size: 1.35rem;
    line-height: 2.25rem;
  }
  .product-block { 
    // border-bottom: 1px $block-border-color solid;
    padding: 10px 0;
    margin: 0;
    label {
      // color: $breadcrumb-color;
      // color: $breadcrumb-color;
    }
    // span {
    //   font-size: 0.875rem;
    // }
  }
  .product-block.product-variants{
    border:none;
  }
  .product-name {
    .product-manufacturer {
      min-width: 150px;
    }
  }
  .product-reference {
    > * {
      font-size: 0.875rem;
      color: $breadcrumb-color;
    }
  }
  .product-ref-manu-time {
    .product-manufacturer {
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 1px;
        height: 10px;
        background: $black;
      }
      a {
        font-size: 0.875rem;
        color: $breadcrumb-color;
        --color: #{$primary-color};
        &:hover {
          color: $primary-color;
        }
      }
    }
    label {
      font-size: 0.875rem;
      color: $breadcrumb-color;
    }
  }
  .product-delivery-information {
    .icon {
      vertical-align: middle;
      color: $primary-color;
    }
    label {
      font-size: 1rem;
      text-transform: none;
    }
  }
  .blockreassurance_product {
    .blockreassurance_product_item {
      align-items: center;
      border-bottom: 1px $block-border-color solid;
      p {
        font-size: 0.875rem;
      }
      &:first-child {
        border-left: 0;
      }
      i {
        font-size: 2rem;
        color: $primary-color;
      }
    }
  }
}

.product-flag {
  background-color: $primary-color;
  &.discount {
    background-color: #E52040;
  }
  &.on-sale {
    background-color: #5D9FA4;
  }  
}

#product-details {
  label {
    color: $breadcrumb-color;
  }
}

.product-prices {
  background-color: #FFFFFFa3;
  .discount {
    &-percentage {
      &::before {
        padding: 0 2px 0 0;
        content: "-";
      }      
    }
    &-amount {
      &::after {
        padding: 0 2px 0 5px;
        content: "/";
      }      
    }
  }
  // .has-discount {
  //   &.product-price,
  //   p {
  //     color: $red;
  //   }
  //   .regular-price {
  //     color: $black;
  //     font-size: 1.25rem;
  //     font-weight: 600;
  //   }
  // }
  .product-price {
    color: $black;
    &.has-discount,
    p {
      color: $red;
    }
    .regular-price {
      color: $black;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  .current-price {
    display: flex;
    align-items: baseline;
    .current-price-value {
      // color: $black;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
  .tax-shipping-delivery-label {
    font-size: 1.25rem;
    color: $black;
  }
  #product-availability {
    label {
      text-transform: none;
      color: $breadcrumb-color;
      &::before {
        content: '';
        width: 15px;
        height: 15px;
        display: inline-block;
        background: transparent;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 20px;
      }
    }
    .product-available {
      &::before {
        background: $green;
      }
    }
    .product-unavailable {
      &::before {
        background: $red;
      }
    }
    .product-last-items {
      &::before {
        background: $circle-orange;
      }
    }  
  }
}

.product-variants-item {
  // border-bottom: 1px $block-border-color solid;
  // padding-right: 15px;
  &:last-of-type {
    border-bottom: 0;
  }
  .attribute-name,
  .radio-label {
    text-transform: none;
  }
  label {
    cursor: pointer;
    // text-transform: none;
    font-weight: 600;
  }
  .input-color {
    .checked-indicator i {
      @extend %custom-checkbox-checked;
    }
    width: 1.5rem;
    height: 1.5rem;
    &:checked ~ .checked-indicator i  {
      @extend %custom-checkbox-checked;
      // &:after {
      //   content: $fibicon-checked;
      //   background: $black;
      //   border-color: $black;
      // }
    }
    &:checked + span,
    &:hover + span {
      border: 2px solid $primary-color;
    }
  }
  // .input-radio {
  //   opacity: 1;
  //   position: relative;
  //   width: 1.5rem;
  //   height: 1.5rem;
  //   display: inline-block;
  // }
  // .custom-radio {
  //   width: 1.5rem;
  //   height: 1.5rem;
  // }
  .radio-label {
    font-weight: 600;
    display: inline-block;
  }
  
  // .attribute-name {
  //   &::after {
  //     @include ibif-icon();
  //     content:'';
  //     width: 24px;
  //     height: 24px;
  //     border: 1px solid $block-border-color;
  //     color: $white;
  //     display: inline-block;
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     font-size: 1rem;
  //     padding: 2px;
  //     text-align: center;
  //   }
  // }
}

.product-description {
  p {
    margin-bottom: 2rem;
    line-height: 1.7;
  }
}

.product-add-to-cart {
  .ib-group-qty-btn {
    width: 200px;
    .bootstrap-touchspin {
      .form-control {
        height: 50px;
        // margin: 0 5px;
      }
      .btn {
        width: 50px;
        line-height: 48px;
        font-weight: 500;
        font-size: 1rem;
        &::before {
          line-height: 50px;
        }  
      }
    }  
  }
  #quantity_wanted {
    // width: 4rem;
    font-weight: 500;
    font-size: 1rem;
    height: 50px;
  }
  .add-to-cart {
    i {
      font-size: 1.4rem;
      vertical-align: middle;
    }
  }
  .wishlist-button-add {
    border: 1px $block-border-color solid;
    border-radius: 0;
    width: 50px;
    height: 50px;
    &.wishlist-button-product {
      margin-left: auto;
    }
    i {
      font-weight: 700;
    }
  }
}

// .product-attachments {
//   ul.download {
//     @include ibif-attachments;
//   }
// }

.product-features {
  > dl.data-sheet {
    dd.value,
    dt.name {
      // margin-right: 1px;
      background: $whiteSmoke;
    }
    
  }
}

#product {
  .product-desc-h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .pagination {
    border: 0;
    a {
      max-width: 150px;
    }
  }  
  #wrapper {
    // > .container-fluid {
    //   display: flex;
    // }
    #main {
      .page-header {
        margin-bottom: 0;
        h2 {
          font-size: 1.875rem;
        }
      }
    }
  }
}

#product-comments-list,
#product-comments-list-footer {
  .post-product-comment {
    font-size: 0.875rem;
  }
}

#product-comments-list {
  .product-comment-list-item:not(#empty-product-comment) {
    background: $whiteSmoke;
    p {
      font-size: 1rem;
      color: $black;
      line-height: 150%;
    }
  }
  .comment-infos {
    color: $primary-color;
    min-height: unset;
  }
  .comment-author {
    font-weight: 600;
    font-size: 1rem;
  }
  .comment-date {
    font-size: 0.875rem;
    .icon {
      font-size: 1.125rem;
    }
  }
}

#post-product-comment-modal,
#product-comment-posted-modal,
#product-comment-post-error {
  &.product-comment-modal {
    .modal-dialog {
      max-width: 1120px;
      margin-top: get-vw(100);
      .modal-content {
        border-radius: 0;
        border: 0;
        padding: 50px;
        .modal-header {
          display: block;
          padding: 0;
          position: relative;
          h2 {
            font-weight: 500;
            padding: 0 25px 10px;
            border-bottom: 1px $block-border-color solid;
            margin: 0;
            float: none;
          }
          .close {
            opacity: 1;
            position: absolute;
            padding: 0.5rem;
            top: -25px;
            right: -25px;
            &:hover {
              opacity: 0.5;
            }
          }
        }
        .modal-body {
          padding: 25px;
          .criterions-block {
            display: none;
            opacity: 0;
            .criterion-rating {
              justify-content: flex-start;
            }
          }
          textarea {
            height: get-vw(150);
          }
          #product-comment-posted-modal-message,
          #product-comment-post-error-message {
            border: 1px $block-border-color solid;
            padding: get-vw(200);
            ul {
              margin: 0;
              position: relative;
              padding-left: get-vw(100);
              li {
                margin: 15px 0;
                font-weight: 300;
                &:first-child {
                  font-size: 1.5rem;
                  line-height: 2rem;
                }
              }
              &::before {
                @include ibif-icon();
                position: absolute;
                left: 0;
                font-size: 38px;
                font-weight: 700;
                top: 50%;
                transform: translateY(-50%);                          
              }
            }
          }
          #product-comment-posted-modal-message {
            ul {
              li {
                color: $green;
              }
              &::before {
                content: $fibicon-checked;
                color: $green;
              }
            }
          }
          #product-comment-post-error-message {
            ul {
              li {
                color: $red;
              }
              &::before {
                content: $fibicon-cancel;
                color: $red;
              }
            }
          }
        }
      }
    }
    .post-comment-buttons {
      justify-content: center;
      p.required {
        left: 15px;
        color: $red;
      }
    }
  }
}

.product-miniature {
  display: flex;
  justify-content: center;
  box-shadow: 0 0 19px 0px #e5dfdf;
  border-radius: 10px;
  .regular-price {
    
  }
  .regular-price ~ .price {
    color: $red;
  }
  .product-title {
    a {
      color: inherit;
      min-height: 65px;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .product-thumbnail {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }
  .add-to-cart {
    border:1px solid $primary-color;
    background-color: $white;
    border-radius: 15px;
    // width: get-vw(44);
    // line-height: 44px;
    color: inherit;
    i.icon {
      top: 2px;
    }
    &:hover{
      color:$white;
      background-color: $primary-color;
    }
  }
  // .add-to-cart,
  .ib-fake-add-to-cart span {
    width: 32px;
    line-height: 32px;
  }
  .ib-price-block {
    padding-bottom: 0.35rem;
  }
  .product-title {
    margin-top: 0.7rem;
    text-align: center;
    text-transform: none;
  }
  .discount-product {
    display: none;
  }
  .product-price-and-shipping {
    font-weight: 700;
    text-align: center;
    flex-wrap: nowrap;
    margin: 0;
  }
}

#blockcart-modal {
  overflow: hidden;
  color: $black;
  padding: 0 20px !important;
  .close {
    @extend .ib-transition-bg;
    background-color: $white;
    text-shadow: none;
    i {
      @extend .ib-transition-all;
      display: inline-block;
      transform: rotate(0deg);
    }
    &:hover {
      background-color: $primary-color;
      i {
        transform: rotate(90deg);
        color: $white;
      }
    }
  }
  .cart-content-btn {
    width: 100%;
    // padding-right: get-vw(50);
  }
  .cart-content {
    button.btn {
      display: flex;
      align-items: center;
    }
    .btn {
      font-size: 14px;
      // padding-right: 0;
      justify-content: center;
      line-height: 100%;
    }
    .btn .icon {
      font-size: 20px;
      width: 50px;
    }
    p {
      color: inherit;
      font-size: inherit;
    }
    // p.ib-font-lg {
    //   margin-bottom: 1.5rem;
    // }
  }
  .product-price {
    color: inherit;
  }
  .modal-title {
    &::after {
      content: '';
      display: block;
      width: 15px;
      border-bottom: 1px solid;
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
    }
  }
}

.product-flags {
  top: 10px;
  li.product-flag {
    font-size: 12px;
    line-height: 21px;
    min-width: 90px;
    margin-top: 0.375rem;
    text-align: center;
    font-weight: 600;
    pointer-events: none;
  }
}
.products.row {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: white;
    z-index: 1;
  }
  // > .product {
  //   border-left: 1px solid #F0F0F0;
  // }
}

// SWIPER
.swiper-slide-thumb-active {
  img {
    border-radius: 5px;
    border: 1px solid $primary-color;
  }
}

.images-container {
  .swiper-slide {
    cursor: pointer;
  }
}

.product-images {
  > li.thumb-container {
    display: inline;

    > .thumb {
      margin-bottom: $small-space;
      cursor: pointer;

      .images-container & {
        margin-right: 0.8125rem;
      }

      // &.selected,
      &:hover {
        border-radius: 5px;
        border: $primary-color 1px solid;
      }
    }
  }
}

.page-content-mobile {
  h1 {
    font-size: 18px;
    line-height: 28px;
  }
  .product-ref-manu-time {
    label,
    span {
      font-size: 12px;
    }
  }
  .product-ref-manu-time {
    .product-manufacturer{
      &::before {
        display: none;
      }
      a {
        font-size: 12px;
      }
      &-logo {
        right: 0;
        img {
          width: auto;
          max-height: 65px;
        }
      }
    }
  }
}

.add-to-cart.position-fixed {
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  &.ib-docked {
    opacity: 1;
    visibility: visible;
  }
}

.products.ib-products-list > .product {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  // border-left: 1px $ib-index-hr-gray solid;
}

.block-category {
  flex-wrap: wrap;
}


/*********************/
/*        RWD        */
/*********************/

@include media-breakpoint-down(md) {
  .div-gift{
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;
    .custom-radio i{
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  #contact-infos .icon.pigamicon-mobile-notch{
    font-size: 2rem;
    margin-left: -2.8rem;
  }
  #product .product-container .product-actions .add-to-cart{
    padding:15px 0;
  }
  #product .custom-radio span.radio-label{
    padding: 15px 0!important;
  }
  .product-miniature .add-to-cart{
    padding:5px 15px;
  }
  [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1;
    transform: none;
  }
  #blockcart-modal {
    .cart-content .cart-content-btn {
      display: block;
      padding-right: 0;
    }
    .cart-content .btn {
      justify-content: center;
      margin-top: 0.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-content-mobile {
    display: none !important;
  }
  .product-prices {
    #product-availability 
    {
      order: 2;
    }
    .product-price-discount {
      order: 1;
    }
  }
}

@include media-breakpoint-down(xs) {
  #blockcart-modal {
    padding: 0 !important;
  }
}

@include media-breakpoint-down(sm) {

  .product-quantity .qty{
    width: 100%;
  }
  .page-content-desktop {
    .product-prices {
      > div {
        width: 100%;
        text-align: center;
      }
      // .product-price-discount {
        // > .product-discount {
          // display: none;
        // }
      // }
      // display: block !important;
    }
    .current-price {
      flex-wrap: wrap;
      align-items: start;
      .current-price-value,
      .product-discount {
        // width: 50%;
        // margin-right: 0 !important;
      }
      .tax-shipping-delivery-label {
        width: 100%;
      }
    }
  }
  #product .product-accessories {
    border-top: 1px $block-border-color solid;
    .product-desc-h3 {
      text-align: center;
      font-size: 25px;
      font-weight: 500;
    }
  }
  #blockcart-modal {
    .product-image {
      max-width: 150px;
      margin: 0 auto 1.5rem;
    }
    .cart-content .value {
      text-align: right;
      margin-left: 0.5rem;
    }
    .modal-title, 
    .cart-products-count {
      text-align: center;
    }
    .modal-title::after {
      display: none;
    }
    .product-price {
      border-top: 1px solid $block-border-color;
    }
  }
}
#product{
  .oos {
    > .custom-radio input[type="radio"] + span {
      text-decoration: line-through;
    }
  }
  .product-accessories {
    .items {
      gap: 1rem;
      .item {
        border: solid 1px $block-border-color;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
          border-color: $primary-color;
        }
        &.current {
          border-width: 2px;
          border-color: $primary-color;
        }
      }
    }
  }
  .images-container {
    .accessory-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      max-width: 100%;
      background-color: $white;
    }
  }
  .custom-radio input[type="radio"]:checked
  {
   
    & + span
    {
      position: unset;
      border-color: $primary-color;
      background-color: $white;
    }
  }

  .product-features > dl.data-sheet dd.value{
    font-weight: 700;
  }
  .custom-radio{
    width: 100%;
    height: 100%;
    border:none;
    
    span.radio-label{
      text-align: center;
      font-weight: 400;
      width: 100%;
      height: 100%;
      padding: 0.65vw 1.04167vw;
      border-radius: 10px;
      border: 2px solid #e0e0e0;
    }
    input[type="radio"]{
     
      background-color: $white;
      color: #fff;
    }
  }
}
.gm_omniprice{
  background-color: #f4f4f4;
  border-radius: 7px;
  padding:5px;
  font-weight: 600;
  // margin-top: 10px;
}
@include media-breakpoint-up(lg) {
  #product .product-variants-item .div-gift{
    display: grid;
    grid-template-columns: get-vw(60) auto;
    padding:get-vw(10) 0;
    i.icon{
        padding:0 get-vw(20);
        font-size: get-vw(25);
    }
    .gift-price{
        // height: 100%;
        // align-items: flex-end;
        // justify-content: flex-end;
        font-size: get-vw(15);
        padding-right: get-vw(25);
        // margin-bottom: get-vw(5);
    }
    label{
        margin-top: get-vw(20);
        font-size: get-vw(16);
        .ets_checkbox{
            margin-right: get-vw(10);
        }
    }
}
  #product .gm_omniprice{
    font-size: get-vw(13);
    padding:get-vw(12) get-vw(25);
    // margin-top: get-vw(28);
  }
  .product-container h1{
    font-size: get-vw(24);
  }
  .product-prices .current-price .current-price-value{
    font-size: get-vw(30);
  }
  .product-features > dl.data-sheet dd.value, .product-features > dl.data-sheet dt.name{
    padding: get-vw(15) get-vw(40);
    font-size: get-vw(17);
  }
  .product-features > dl.data-sheet dd.value{
    flex: 1 0 36%;
  } .product-features > dl.data-sheet dt.name{
    flex: 1 0 64%;
  }
  #product .product-desc-h3{
    font-size: get-vw(20);
  }
  #product .custom-radio{
    margin-right: 0.2vw;
  }
  #product .custom-radio input[type="radio"] + span{
    font-size: 0.92vw;
  }
  #product #wrapper #main .page-header h2{
    font-size: 1.5625vw;
    font-weight: 900;
  }
  #product {
    .pagination {
      // border-top: 1px $block-border-color solid;
      // border-bottom: 1px $block-border-color solid;
      a {
        max-width: none;
      }
    }  
  }  
    // #category .products.row > .product {
  .products.ib-products-list > .product {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .product-miniature {
    // .add-to-cart,
    .ib-fake-add-to-cart span {
      width: get-vw(44);
    }
    .add-to-cart {
      font-size: get-vw(15);
      height: 100%;
      padding:get-vw(5) get-vw(20);
      // line-height: get-vw(44);
      i.icon {
        top: get-vw(4);
      }
    }
    .product-title {
      a {
        min-height: get-vw(65);
      }
    }
  }

  .product-prices {
    #product-availability {
      label {
        &::before {
          content: '';
          width: get-vw(15);
          height: get-vw(15);
          margin-right: get-vw(10);
        }  
      }
    }    
  }
  
  .product-flags {
    top: get-vw(10);
    li.product-flag {
      font-size: get-vw(12);
      line-height: get-vw(21);
      min-width: get-vw(90);
      // margin-top: 0.375rem;
    }
  }
  .products.row {
    // > .product {
    //   border-left: 1px solid #F0F0F0;
    // }
    > .swiper-wrapper > .product {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        border-left: 1px solid #F0F0F0;
      }
    }
  }
  .product-container {
    // .product-block {
    //   padding: 1.5vw 1.25vw 1.5vw 1.05vw;
    // }
    .product-ref-manu-time {
      .product-manufacturer {
        &::before {
          top: get-vw(5);
          width: get-vw(1);
          height: get-vw(10);
        }
      }
    }  
  }
  .product-variants-item {
    label {
      max-width: get-vw(250);
    }
  }
  // .add-to-cart,
  .ib-fake-add-to-cart span {
    width: 44px;
  }
  .product-add-to-cart {
    .ib-group-qty-btn {
      width: get-vw(190);
      .bootstrap-touchspin {
        .form-control {
          height: get-vw(50);
          // margin: 0 5px;
        }
        .btn {
          width: get-vw(50);
          font-size: get-vw(1.2rem) !important;
          line-height: get-vw(50);
          &::before {
            line-height: get-vw(50);
          }  
        }
      }  
    }  
    #quantity_wanted {
      // width: 4rem;
      height: initial;
      font-size: get-vw(1rem) !important;
    }
    .wishlist-button-add {
      width: get-vw(50);
      height: get-vw(50);
      &.wishlist-button-product {
        margin-left: get-vw(10);
      }  
    }
  }
  .ib-product-images-col {
    // max-width: get-vw(675);
    flex: 0 0 54%;
    max-width: 54%;
    + div {
      flex: 0 0 46%;
      max-width: 46%;
    }
  }
  .blockreassurance_product {
    .blockreassurance_product_item {
      border-bottom: 0 !important;
      border-left: 1px $block-border-color solid;
      &:first-child {
        border-left: 0;
      }
      i {
        font-size: 2rem;
        color: $primary-color;
      }
      p {
        span { 
          display: block;
        }
      }
    }
  }
  
  #blockcart-modal {
    // padding: 0 20px !important;
    // .cart-content-btn {
    //   padding-right: get-vw(50);
    // }
    .modal-body {
      padding: get-vw(3.125rem) get-vw(1.875rem);
    }
    .cart-content {
      .btn {
        font-size: get-vw(14);
      }
      .btn .icon {
        font-size: get-vw(20);
        width: get-vw(50);
      }
      // p.ib-font-lg {
      //   margin-bottom: get-vw(1.5rem);
      // }
    }
    .modal-title::after {
      width: get-vw(15);
      margin-top: get-vw(1.75rem);
      margin-bottom: get-vw(1.75rem);
    }
    .modal-dialog {
      max-width: get-vw(1120);
    }
  }

  .js-mailalert {
    .input-group-append {
      button {
        width: auto;
      }
    }
    .w-100 {
      width: auto !important;
    }
  }
}

@include media-breakpoint-down(lg) {
  .product-container {
    .product-ref-manu-time {
      flex-wrap: wrap;
      > div {
        width: 100%;
        margin: get-vw(10) 0 !important;
      }
      .product-manufacturer {
        label {
          padding-left: 0;
        }
        &::before {
          display: none;
        }
      }
    }
    .product-delivery-information .icon {
      vertical-align: top;
    }
    .product-prices #product-availability {
      font-size: 0.875rem;
      label {
        font-size: 0.875rem;
      }
    }
    // .product-actions .add-to-cart {
    //   padding-top: get-vw(0.625rem);
    // }
    // .product-add-to-cart .add-to-cart i {
    //   font-size: 0.875rem;
    // }
  }
}